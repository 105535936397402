<div [formGroup]="currentForm">
    <div >
        <div class="title d-flex align-items-center flex-row mb-3 justify-content-between">
            <div class="d-flex">
                <h3 class="title">{{ title | translate}}</h3>
                <mat-icon *ngIf="!tempNew && !tempEditnewCreateItem"
                    (click)="createNew()" class="cursor-pointer add-icon">add</mat-icon>
                <mat-icon *ngIf="tempNew" (click)="closeCreatenew()" class="cursor-pointer add-icon">close</mat-icon>
            </div>
            <div *ngIf="!hideRequestInfo">
                <mat-icon  (click)="requestCandidateInfo(this.current)" class="cursor-pointer">mail</mat-icon>
            </div>
        </div>
        <div *ngIf="tempNew" class="inner-card-section new-item">
            <div class="field-body text-center w-100">
                <div class="d-flex flex-column">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ placeholder | translate}}</mat-label>
                        <input type="text" placeholder="Pick one" aria-label="type" matInput [formControlName]="current"
                            [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let item of filtered | async" [value]="item">
                                {{item}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <div *ngIf="currentForm.get(current)?.touched && currentForm.get(current)?.invalid"
                        class="alert alert-danger">
                        <div
                            *ngIf="currentForm.get(current)?.errors?.required">
                            {{'shared.fieldError' | translate}}</div>
                    </div>
                    <mat-form-field appearance="outline" class="second-btn">
                        <mat-label>{{'stepperForm.language.level' | translate}}</mat-label>
                        <mat-select formControlName="level" matNativeControl>
                            <mat-option *ngFor="let item of levels" [value]="item">
                                {{item | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="currentForm.get('level')?.touched && currentForm.get('level')?.invalid"
                        class="alert alert-danger second-btn">
                        <div
                            *ngIf="currentForm.get('level')?.errors?.required">
                            {{'shared.fieldError' | translate}}</div>
                    </div>
                </div>
            </div>
            <div class="new-item-nav mb-3 d-flex justify-content-evenly">
                <button type="button" mat-stroked-button data-first-save
                    (click)="closeCreatenew()" class="close">{{'shared.close' |
                    translate}}</button>
                <button type="button" mat-stroked-button (click)="saveNew()"
                    [disabled]="!currentForm?.valid"
                    [ngClass]="currentForm?.valid ? 'valid-submit-button' : ''"
                    class="save second-btn">{{'shared.save' | translate}}</button>
            </div>
        </div>

        <div class="expansion-content">
            <div class="d-flex justify-content-between"
                *ngFor="let item of unchangableItems; let i = index">
                <mat-accordion class="w-100" [multi]="!edit ? true : false">
                    <mat-expansion-panel #panel [hideToggle]=true>
                        <mat-expansion-panel-header style="cursor: default;" #panelH (click)="panelH._toggle()"
                            class="header-no-hover">
                            <mat-panel-title>
                                <span class="w-100">{{item[current]}} unchange</span>
                            </mat-panel-title>
                            <mat-panel-description class="d-flex justify-content-end">
                                <span class="w-100 text-right">{{item.level | translate}}</span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div class="d-flex justify-content-between"
                *ngFor="let item of items; let i = index">
                <mat-accordion class="w-100" [multi]="!edit ? true : false">
                    <mat-expansion-panel #panel [hideToggle]=true>
                        <mat-expansion-panel-header style="cursor: default;" #panelH (click)="panelH._toggle()"
                            class="header-no-hover">
                            <mat-panel-title>
                                <span *ngIf="edit" class="w-100">{{item[current]}}</span>
                                <span *ngIf="!edit" class="w-100">{{item[current]}}</span>
                            </mat-panel-title>
                            <mat-panel-description class="d-flex justify-content-end"
                                [ngStyle]="{'margin-right': !edit ? '.5rem' : '0'}">
                                <span *ngIf="edit">{{item.level | translate}}</span>
                                <span *ngIf="!edit" class="w-100 text-right">{{item.level | translate}}</span>
                                <div class="d-flex">
                                    <mat-icon
                                        (click)="editItem(i, panel)"
                                        class="ml-2 mr-2" *ngIf="!edit || step !== i"
                                        [ngStyle]="{'cursor': !edit ? 'pointer' : 'default'}"
                                        matTooltip="{{'shared.edit' | translate}}">edit</mat-icon>
                                    <mat-icon (click)="removeItem( i, panel)"
                                        class="ml-2 mr-2 icon-delete" *ngIf="edit && step === i"
                                        matTooltip="{{'dashboardSettings.userManagement.delete' | translate}}">
                                        delete_forever</mat-icon>
                                    <mat-icon *ngIf="edit && step === i"
                                        (click)="saveEditItem(i, panel)"
                                        class="icon-save" matTooltip="{{'shared.save' | translate}}">save</mat-icon>
                                    <mat-icon *ngIf="edit && step === i"
                                        (click)="closeEditItem(i, panel)"
                                        class="icon-close" matTooltip="{{'shared.cancel' | translate}}" >cancel
                                    </mat-icon>
                                </div>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="d-flex align-start ml-3" [ngClass]="{'another-items': i >= 1}">
                            <mat-form-field appearance="outline" class="w-100" *ngIf="edit">
                                <mat-label>{{'stepperForm.language.language' | translate}}</mat-label>
                                <input type="text" placeholder="Pick one" aria-label="type" matInput
                                    [formControlName]="current" [matAutocomplete]="auto">
                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                    <mat-option *ngFor="let filteredItem of filtered | async" [value]="filteredItem">
                                        {{filteredItem}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div class="d-flex align-start ml-3">
                            <div *ngIf="!edit" class="d-flex w-100">
                                <p>{{'stepperForm.language.level' | translate}}:</p>
                                <span>{{item.level | translate}}</span>
                            </div>
                            <mat-form-field appearance="outline" class="w-100" *ngIf="edit">
                                <mat-label>{{'stepperForm.language.level' | translate}}</mat-label>
                                <mat-select formControlName="level" matNativeControl required>
                                    <mat-option *ngFor="let level of levels"
                                        [value]="level">
                                        {{level | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
</div>