import { NewRegistrationQuestion } from '../registration-questions.model';

export class UserFromBE {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: null;
  company?: string;
  active?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  tenantID?: number;
  tenant?: Tenant;
  session?: string;
  emailVerified?: boolean;
  verificationHash?: string;
  dateOfEmailVerification?: string;
  lastActivity?: string;
  photoID?: number;
  dateInvited?: string;
  invitedByUserID?: number;
  inviteHash?: string;
  dateAccepted?: string;
  roles?: Role[];
  userType?: string;
  marketing?: boolean;
  privacyPolicy?: boolean;
  title?: string;
  titleAfterName?: string;
  telephone?: string;
  shortcvID?: number;
  shortcv?: ShortCV;
  PersonalDataID?: number;
  longcv?: Longcv;
  userAttachments?: UserAttachments[];
  userPreferences?: string;
}

export class Tenant {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: null;
  name?: string;
  logo?: string;
  active?: boolean;
  tenantType?: string;
  companyID?: string;
  taxID?: string;
  vat?: string;
  inRegister?: string;
  companyRepresentativeName?: string;
  companyRepresentativeEmail?: string;
  employeesNumber?: string;
  tenantCharacteristic?: string;
  tenantBenefits?: string[];
  tenantAddresses: TenantAddress[];
}
export class TenantAddress {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
  TenantID: number;
  streetName: string;
  streetNumber: string;
  city: string;
  zip: string;
  poBox: string;
  country: string;
  billing: boolean;
}

export class Role {
  id?: number;
  value?: string;
}

export class ShortCV {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: null;
  cityCountry?: string;
  questionsID?: number;
  questions?: NewRegistrationQuestion;
}
export class Longcv {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: null;
  gender?: string;
  title?: string;
  titleAfterName?: string;
  birthDate?: string;
  telephone?: string;
  streetName?: string;
  streetNumber?: number;
  country?: string;
  city?: string;
  zip?: number;
  PersonalDataLanguages?: PersonalDataLanguage[];
  workExperiences?: WorkExperience[];
  universityEducations?: UniversityEducation[];
  highSchoolEducations?: HighSchoolEducation[];
  languages?: Language[];
  skills?: Language[];
}

export class HighSchoolEducation {
  highSchoolName?: string;
  fieldOfStudy?: string;
  city?: string;
  country?: string;
  startDate?: string;
  endDate?: string;
  candidateAttachments?: CandidateAttachments;
  attachmentID?: number;
  personalDataID?: number;
}

export class CandidateAttachments {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: null;
  url?: string;
  fileType?: string;
  type?: string;
}

export class PersonalDataLanguage {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: null;
  pdLanguageName?: string;
  pdLanguageLevel?: string;
  personalDataID?: number;
}

export class UniversityEducation {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: null;
  universityName?: string;
  fieldOfStudy?: string;
  city?: string;
  country?: string;
  startDate?: string;
  endDate?: string;
  candidateAttachments?: CandidateAttachments;
  attachmentID?: number;
  current?: boolean;
  personalDataID?: number;
}

export class Language {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: null;
  language?: string;
  level?: string;
  personalDataID?: number;
  skillName?: string;
}

export class WorkExperience {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: null;
  position?: string;
  employer?: string;
  city?: string;
  country?: string;
  startDate?: string;
  endDate?: string;
  current?: boolean;
  personalDataID?: number;
}

export class UserAttachments {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
  url: string;
  fileType: string;
  type: string;
  fileName: string;
  entityID: number;
  model: string;
  tenant: Tenant;
  tenantID: number;
}

export class UserPreferences {
CandidatesPreferences?: string;
JobOffersPreferences?: string;
UserId?: number;
id?: number;
Language?:string;
}

export class TablePreferences{
columns?: string[];
sortDirection: string;
sortColumn?: string;
statuses?: string[];
textSearch?: string;
dateStart?: string;
dateEnd?: string;
}

export class CandidatesPreferences{
candidateColumns?: string[];
sortDirection?: string;
sortColumns?: string;
}

export class JobOffersPreferences{
jobOffersColumns?: string[];
sortDirection?: string;
sortColumns?: string;
}
