<div class="field-body d-flex mt-3">

    <p class="rightHeadings">{{ title | translate}}:</p>
    <div *ngIf="!edit" class="mt-auto">
        <span>{{ getString() }} </span>
    </div>
    <div *ngIf="edit" class="edit">
        <div data-multiselect class="d-flex flex-column" required>
            <mat-checkbox matCheckbox *ngFor="let option of combinedArray" class="button-like mt-2" type="checkbox"
                [checked]="isChecked(option.value)" (change)="onCheckboxChange($event, option.value)"
                class="button-like" value="{{option.value}}">
                {{option.text | translate}}
            </mat-checkbox>
        </div>
        <div *ngIf="controlArray.invalid" class="alert alert-danger">
            <div *ngIf="controlArray.errors?.requireOneCheckboxToBeChecked">
                {{'shared.fieldErrorSelectOne' | translate}}</div>
        </div>
    </div>
</div>