import { NewRegistrationAttachment } from '../new-registration.model';
import { UserFromBE } from './user-from-be';

export class ApplyToJobUser {
    applyToJobData?: ApplyToJobData;
    user?: UserFromBE;
}

export class ApplyToJobData {
    additionalInfo?: string;
    jobAttachments?: NewRegistrationAttachment[];
}
