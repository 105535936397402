import { NewRegistrationHighSchool,
    NewRegistrationAttachment,
    NewRegistrationUniversity,
    NewRegistrationProject,
    NewRegistrationLanguage,
    NewRegistrationWorkExperience,
    NewRegistrationSkills, } from '../../new-registration.model';

export class FinalCandidateHRCvrModel {
    id?: number;
    createdAt?: string;
    status!: string;
    gender!: string;
    name!: string;
    surname!: string;
    title!: string;
    titleAfterName?: string;
    birthDate!: Date;
    email!: string;
    telephoneNumber!: string;
    streetName!: string;
    streetNumber!: number;
    zip!: number;
    city!: string;
    country!: string;
    hrHighSchoolEducations!: NewRegistrationHighSchool[];
    hrUniversityEducations!: NewRegistrationUniversity[];
    // courses!: NewRegistrationCourse[];
    projects!: NewRegistrationProject[];
    hrLanguages!: NewRegistrationLanguage[];
    hrWorkExperiences!: NewRegistrationWorkExperience[];
    hrSkills!: NewRegistrationSkills[];
}
