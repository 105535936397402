import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Editor, Toolbar } from 'ngx-editor';
import { EmailSnippet } from '../../../index';
import { FinalCandidateHRCvrModel } from '../../models/dashboard';
import { GoogleService } from '../google.service';

@Component({
  selector: 'talentway-fe-gmail-new-email',
  templateUrl: './gmail-new-email.component.html',
  styleUrls: ['./gmail-new-email.component.scss'],
})
export class GmailNewEmailComponent implements OnInit {
  @Input() candidate: FinalCandidateHRCvrModel;
  @Input() isResponse? = false;
  @Input() responseEmail?: EmailSnippet
  @Output() emailSent: EventEmitter<EmailSnippet> = new EventEmitter()

  form: FormGroup;

  editor: Editor;
  html = '';

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  constructor(
    private fb: FormBuilder,
    private googleService: GoogleService,
  ) {
    this.editor = new Editor();

  }

  ngOnInit(): void {
    this.form = this.fb.group({
      to: this.fb.control(this.candidate.email,{}),
      cc: this.fb.control('',{}),
      subject: this.fb.control(this.responseEmail ? this.responseEmail.subject : 'TALENTWAY: Job Reaction',{}),
      body: this.fb.control('',{}),
      threadId: this.fb.control(this.responseEmail ? this.responseEmail.threadId : null,{}),
      mailId: this.fb.control(this.responseEmail ? this.responseEmail.mailId : null,{})
    })
  }

  sendEmail(){
    if(this.responseEmail !== undefined){
      const subject = this.responseEmail.subject.startsWith("RE:", 0) ? this.responseEmail.subject : "RE: " + this.responseEmail.subject
      this.form.get('subject').setValue(subject)
      this.form.get('threadId').setValue(this.responseEmail.threadId)
      this.form.get('mailId').setValue(this.responseEmail.mailId)
    }
    this.googleService.sendEmail(this.form.value).subscribe(res => {
      this.emailSent.emit(res.body);
      this.form.get('body').setValue('');
    })
  }
}
