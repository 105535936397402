import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GmailSnippetComponent } from './gmail-snippet/gmail-snippet.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { GmailNewEmailComponent } from './gmail-new-email/gmail-new-email.component';
import { NgxEditorModule } from 'ngx-editor';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GmailThreadComponent } from './gmail-thread/gmail-thread.component';
import { GmailThreadMessageComponent } from './gmail-thread/gmail-thread-message/gmail-thread-message.component';
import { MatDividerModule } from '@angular/material/divider';
import { GoogleNewEventComponent } from './google-new-event/google-new-event.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleLoginComponent } from './google-login/google-login.component';

@NgModule({
  declarations: [
    GmailSnippetComponent,
    GmailNewEmailComponent,
    GmailThreadComponent,
    GmailThreadMessageComponent,
    GoogleNewEventComponent,
    GoogleLoginComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    MatInputModule,
    NgxMatTimepickerModule,
    MatDividerModule,
    TranslateModule,
    MatDatepickerModule,
    MatButtonModule,
    NgxEditorModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
  ],
  exports: [
    GmailSnippetComponent,
    GmailNewEmailComponent,
    GmailThreadComponent,
    GmailThreadMessageComponent,
    GoogleNewEventComponent,
    GoogleLoginComponent,
  ],
})
export class GoogleLibModule {}
