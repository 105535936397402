<div class="card d-flex flex-column container">
    <div class="head">

        <p>{{snippet.from}}</p>
        <p>{{snippet.date | date}}</p>
    </div>
    <mat-divider></mat-divider>
    <div class="content">
        <div *ngIf="snippet.isHtml" [innerHtml]="snippet.content"></div>
        <pre *ngIf="!snippet.isHtml">{{snippet.content}}</pre>
    </div>
</div>