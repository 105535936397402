<div class="background-img d-flex flex-center flex-column flex-column-fluid">
  <mat-card class="container login-container p-4" *ngIf="registerForm" >
    <form [formGroup]="registerForm">
      <h2>{{'register.title' | translate}}</h2>
      <mat-divider></mat-divider>
      <div class="d-flex flex-column mt-3 pt-3">
        <div class="field-container">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label >{{'firmRegistration.firmL' | translate}}</mat-label>
            <input matInput placeholder="Placeholder" formControlName="company" autocomplete="organization">
          </mat-form-field>
          <div *ngIf="registerForm.get('company')?.touched && registerForm.get('company')?.invalid" class="alert alert-danger">
              <div *ngIf="registerForm.get('company')?.errors?.required">{{'shared.fieldError' | translate}}</div>
          </div>
        </div>
        <div class="field-container">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{'questions.finalStepName' | translate}}</mat-label>
            <input matInput placeholder="Placeholder" formControlName="firstName" autocomplete="given-name">
          </mat-form-field>
          <div *ngIf="registerForm.get('firstName')?.touched && registerForm.get('firstName')?.invalid" class="alert alert-danger">
              <div *ngIf="registerForm.get('firstName')?.errors?.required">{{'shared.fieldError' | translate}}</div>
          </div>
        </div>
        <div class="field-container">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{'questions.finalStepSurname' | translate}}</mat-label>
            <input matInput placeholder="Placeholder" formControlName="lastName" autocomplete="family-name">
          </mat-form-field>
          <div *ngIf="registerForm.get('lastName')?.touched && registerForm.get('lastName')?.invalid" class="alert alert-danger">
              <div *ngIf="registerForm.get('lastName')?.errors?.required">{{'shared.fieldError' | translate}}</div>
          </div>
        </div>
        <div class="field-container">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{'stepperForm.personalInfo.email' | translate}}</mat-label>
            <input matInput placeholder="Placeholder" formControlName="email" type="email" autocomplete="email">
          </mat-form-field>
          <div *ngIf="registerForm.get('email')?.touched && registerForm.get('email')?.invalid" class="alert alert-danger">
                <div *ngIf="registerForm.get('email')?.errors?.email">{{'shared.emailErroInvalid' | translate}}</div>
                <div *ngIf="registerForm.get('email')?.errors?.required">{{'shared.emailErrorRequired' | translate}}</div>
            </div>
        </div>
        <div class="field-container">
            <mat-form-field class="pass w-100" appearance="outline">
                <mat-label>{{'shared.password' | translate}}</mat-label>
                <div class="d-flex align-items-end">
                    <input formControlName="password" autocomplete="new-password" matInput matNativeControl placeholder="{{'shared.passwordPlaceholder' | translate}}" [type]="hidePS1 ? 'password' : 'text'">
                    <mat-icon (click)="toggleHidePassword('PS1')">{{hidePS1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                </div>
            </mat-form-field>
            <div *ngIf="registerForm.get('password')?.touched && registerForm.get('password')?.invalid" class="alert alert-danger">
                <div *ngIf="registerForm.get('password')?.errors?.required">{{'shared.passwordErrorRequired' | translate}}</div>
                <div *ngIf="registerForm.get('password')?.errors?.minlength">{{'shared.passwordErrorMinlength' | translate}}</div>
            </div>
        </div>

        <div class="field-container">
          <mat-form-field  class="pass w-100" appearance="outline">
            <mat-label>{{'shared.verifyPassword' | translate}}</mat-label>
            <div class="d-flex align-items-end">
              <input formControlName="verifyPassword" autocomplete="new-password" matInput matNativeControl placeholder="{{'shared.verifyPasswordPlaceholder' | translate}}" [type]="hidePS2 ? 'password' : 'text'">
              <mat-icon (click)="toggleHidePassword('PS2')">{{hidePS2 ? 'visibility_off' : 'visibility'}}</mat-icon>
            </div>
          </mat-form-field>
          <div *ngIf="registerForm.get('verifyPassword')?.touched && registerForm.get('verifyPassword')?.invalid" class="alert alert-danger">
            <div *ngIf="registerForm.get('verifyPassword')?.errors?.required">{{'shared.verifyPasswordErrorRequired' | translate}}</div>
            <div *ngIf="registerForm.get('verifyPassword')?.errors?.mustMatch">{{'shared.verifyPasswordErrorMatch' | translate}}</div>
          </div>
        </div>
        <div class="field-container">
          <p><mat-checkbox class="finalCheckbox"formControlName="marketing" required>{{'questions.finalStepMarketingPart1' | translate}} <a href="{{'questions.finalStepPrivacyPolicyLinkURL' | translate}}">{{'questions.finalStepPrivacyPolicyLink' | translate}}</a></mat-checkbox></p>
          <div *ngIf="registerForm.get('marketing')?.touched && registerForm.get('marketing')?.invalid" class="alert alert-danger">
              <div *ngIf="registerForm.get('marketing')?.errors?.required">{{'questions.finalStepMarketingError' | translate}}</div>
          </div>
          <p><mat-checkbox class="finalCheckbox"formControlName="privacyPolicy" required>{{'questions.finalStepPrivacyPolicyPart1' | translate}} <a href="{{'questions.finalStepPrivacyPolicyTermsOfUseURL' | translate}}">{{'questions.finalStepPrivacyPolicyTermsOfUse' | translate}}</a> {{'questions.finalStepPrivacyPolicyPart2' | translate}} <a href="{{'questions.finalStepPrivacyPolicyLinkURL' | translate}}">{{'questions.finalStepPrivacyPolicyLink' | translate}}</a></mat-checkbox></p>
          <div *ngIf="registerForm.get('privacyPolicy')?.touched && registerForm.get('privacyPolicy')?.invalid" class="alert alert-danger">
              <div *ngIf="registerForm.get('privacyPolicy')?.errors?.required">{{'questions.finalStepPrivacyPolicyError' | translate}}</div>
          </div>
        </div>
        <button mat-raised-button class="success-button" (click)="register()" [disabled]="!registerForm.valid">{{'register.register' | translate}}</button>
      </div>
    </form>
  </mat-card>
</div>
