import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/modules/shared.module';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { PasswordResetDialog } from './password-reset/password-reset-dialog';
import { PasswordResetComponent } from './password-reset/password-reset.component'

@NgModule({
  declarations: [
    LoginComponent,
    PasswordResetDialog,
    PasswordResetComponent
  ],
    imports: [
        CommonModule,
        RouterModule.forChild(
            [
                {
                    path: '',
                    component: LoginComponent
                },
                {
                    path: 'passwordReset',
                    component: PasswordResetComponent
                }
            ]
        ),
        SharedModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatDividerModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        TranslateModule,
        MatDialogModule
    ],
  exports: [LoginComponent],
  providers: []
})
export class LoginModule {
}
