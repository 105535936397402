export class EmailSnippet {
    from: string;
    content: string;
    date: string;
    subject: string;
    mailId: string;
    threadId: string;
    isMe?: boolean;
    isHtml?: boolean;
}

export class EmailSnippetResponse {
    body: EmailSnippet[] | null;
    message: string;
    status: number;
}
export class EmailSingleSnippetResponse {
    body: EmailSnippet;
    message: string;
    status: number;
}

export class GoogleSingleEventResponse {
    body: GoogleEvent;
    message: string;
    status: number;
}

export class GoogleEventsResponse {
    body: GoogleEvent[];
    message: string;
    status: number;
}

export class GoogleEvent {
    link: string;
    title: string;
    description: string;
    start: Date;
    end: Date;
    colorID: string;
    id: string;
    attendees: string[];
}

export class CreateGoogleEvent {
    link: string;
    title: string;
    description: string;
    start: Date;
    end: Date;
    colorID: string;
    id: string;
    attendees: string;
}

export const GoogleColors : string[] = ["rgb(3, 155, 229)", "rgb(121, 134, 203)", "rgb(51, 182, 121)", "rgb(142, 36, 170)", "rgb(230, 124, 115)", "rgb(246, 191, 38)", "rgb(244, 81, 30)", "rgb(213, 0, 0)", "rgb(97, 97, 97)", "rgb(63, 81, 181)", "rgb(11, 128, 67)", "rgb(213, 0, 0)"];
