import { Component, Input, OnInit } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CardItemNonExpandableComponent } from '../card-item-non-expandable/card-item-non-expandable.component';

@Component({
  selector: 'card-item-expandable',
  templateUrl: './card-item-expandable.component.html',
  styleUrls: ['./card-item-expandable.component.scss', '../card-item-non-expandable/card-item-non-expandable.component.scss'],
})
export class CardItemExpandableComponent extends CardItemNonExpandableComponent implements OnInit {

}
