import { Component, Input, OnInit } from '@angular/core';
import { CardItemComponent } from '../card-item.component';

@Component({
  selector: 'app-card-item-text',
  templateUrl: './card-item-text.component.html',
  styleUrls: ['./card-item-text.component.scss', './../card-item.component.scss']
})
export class CardItemTextComponent extends CardItemComponent{

  constructor() {
    super()
   }


}
