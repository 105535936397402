// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.talentway.net/',
  // apiUrl: 'http://localhost:1212/',
  MAX_UPLOAD_SIZE: 20000000,
  GAPI_CLIENT_ID: "338496997450-r1gfobd3j6se48osjgdr5ghnt53vitth.apps.googleusercontent.com",
  CONTACT_TEL: "+421903863271",
  CONTACT_NAME: "Tomáš Krebs",
  CONTACT_EMAIL: "tomas.krebs@thespotcowork.com"
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
