<div class="field-body d-flex">
    <div *ngIf="!edit" class="d-flex align-start ml-3 mt-3">
        <p *ngIf="title" class="rightHeadings">{{ title | translate}}:</p>
        <span>{{ control.value}}</span>
    </div>
    <div *ngIf="edit" class="w-100">

        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ placeholder | translate}}</mat-label>
            <input type="text" placeholder="{{ placeholder | translate}}" aria-label="type" matInput
                [formControl]="control" [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let item of filtered | async" [value]="item">
                    {{item}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <div *ngIf="control.invalid" class="alert alert-danger">
            <div *ngIf="control.errors?.required">
                {{'shared.fieldError' | translate}}</div>
        </div>
    </div>
</div>
