import { attachemntFieldSettings } from './../../../models/fieldSettings/attachment-fs';
import { StepperService } from '../../../../../../../apps/talentway-fe/src/app/views/stepper/stepper.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, startWith } from 'rxjs';
import { AllCVOptionsFromDB } from '../../../models/cv-options.model';
import { setting } from '../../../models/fieldSettings/setting.model';
import { NewRegistrationLanguage, NewRegistrationSkills } from '../../../models/new-registration.model';
import { CardItemComponent } from '../card-item.component';
import { UserFromBE } from '../../../models/dashboard/user-from-be';


@Component({
  selector: 'card-item-non-expandable',
  templateUrl: './card-item-non-expandable.component.html',
  styleUrls: ['./card-item-non-expandable.component.scss'],
})
export class CardItemNonExpandableComponent extends CardItemComponent implements OnInit {
  @Input() step: number;
  @Input() tempNew: boolean;
  @Input() loaded: boolean;
  @Input() allCVOptionsFromDB?: AllCVOptionsFromDB;
  @Input() options?: string[];
  @Input() fieldSettings?: Array<setting>;
  @Input() levels?: string[];
  @Input() currentForm: FormGroup;
  @Input() items: any[] = [];
  @Input() unchangableItems: any[] = [];
  @Input() clickableAtt= false;
  @Input() hideExpansionIcons?: boolean;
  @Input() current: string;
  @Input() candidateId: string;
  @Input() hideRequestInfo: boolean;
  @Output() save: EventEmitter<Array<any>> = new EventEmitter<Array<any>>()
  @Output() delete: EventEmitter<{id: string, assType: string, index: number}> = new EventEmitter<{id: string, assType: string, index: number}>()

  tempEditnewCreateItem = false;
  filtered?: Observable<string[]>;
  attachemntFS = attachemntFieldSettings

  tempPanel: MatExpansionPanel;
  cancel: string;
  selectedLanguage: string;
  user: UserFromBE;


  constructor(
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private stepperService: StepperService,
  ) {
    super()
  }

  ngOnInit(): void {
    this.selectedLanguage = localStorage.getItem('language') !== null ? localStorage.getItem('language') : 'en';
    this.user = JSON.parse(localStorage.getItem('user'));
    if(this.current === 'language'){
      this.items = this.items as NewRegistrationLanguage[]
      this.unchangableItems = this.unchangableItems as NewRegistrationLanguage[]
      this.title = 'dashboard.jobOfferDetail.infoLanguages'
      this.placeholder = 'stepperForm.language.language'
    }else if (this.current === 'skillName'){
      this.items = this.items as NewRegistrationSkills[]
      this.unchangableItems = this.unchangableItems as NewRegistrationSkills[]
      this.title = 'dashboard.jobOfferDetail.infoSkills'
      this.placeholder = 'stepperForm.skills.skill'
    }

    this.filtered = this.currentForm.get(this.current)?.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value)),
      );
  }

  private _filter(value: string): string[] {
    let filterValue = '';
    if (value) {
      filterValue = value.toLowerCase();
    }
    // tslint:disable-next-line:no-non-null-assertion
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  requestCandidateInfo(field: string) {
   let fieldType: string
    switch (field) {
      case 'skillName':
        fieldType = 'skills';
        break;
      case 'language':
        fieldType = 'languages';
        break;
      case 'university':
        fieldType = 'university';
        break;
      case 'highSchool':
        fieldType = 'high school education';
        break;
      case 'workExperience':
        fieldType = 'work experience';
        break;
    }
    this.selectedLanguage = localStorage.getItem('language') !== null ? localStorage.getItem('language') : 'en';
    this.stepperService.requestInfo(fieldType, this.candidateId, this.selectedLanguage).subscribe(res => {
      this.translate.get('shared.cancel', { value: '' }).subscribe((resp: string) => {
        this.cancel = resp;
      });
      let msg: string;

      this.translate.get('dashboard.candidateDetail.emailRequest', { value: '' }).subscribe((resp: string) => {
        msg = resp;
      });
      this.snackBar.open(msg, this.cancel, {
        duration: 3000,
        panelClass: ['snackbar-formater']
      });
    })
  }

  editItem(index: number,
    panel: MatExpansionPanel): void {
    if (!this.tempNew) {
      if (!this.tempEditnewCreateItem) {

        this.step = index;
        if (!panel.expanded) {
          this.edit = true;
          panel.expanded = true;
        }
        this.edit = true;
        this.tempEditnewCreateItem = true;
        if (index > -1) {
          this.items[index].edit = true;
          for(let setting of this.fieldSettings){
            if (setting.name === 'candidateAttachment') {
              for (let attSet of this.attachemntFS) {
                this.currentForm.get(setting.name + '.' + attSet.name)?.setValue(this.items[index][setting.name][attSet.name])
              }
            } else {
              this.currentForm.get(setting.name)?.setValue(this.items[index][setting.name]);
            }
          }
          this.currentForm.get('current')?.valueChanges.subscribe(change => {
              if(change){
                this.currentForm.get('endDate').reset();
                this.currentForm.get('endDate').disable();
              }else{
                this.currentForm.get('endDate').enable();
              }
            }
          )
        }
      }
    }
  }

  closeEditItem(index: number,
    panel: MatExpansionPanel): void {
    this.items[index].edit = false;
    this.tempEditnewCreateItem = false;
    this.edit = !this.edit;
    panel.expanded = false;
  }

  saveEditItem(index: number,
    panel: MatExpansionPanel): void {
    if (index > -1) {
      for(let setting of this.fieldSettings){
        if (setting.name === 'candidateAttachment'){
          for (let attSet of this.attachemntFS) {
            this.items[index][setting.name][attSet.name] = this.currentForm.get(setting.name + '.' + attSet.name).value;
          }
        }else{
          this.items[index][setting.name] = this.currentForm.get(setting.name).value;
        }
       }
      this.items[index].edit = false;
      panel.expanded = false;
      this.currentForm.get('edit').setValue(false);
      this.tempEditnewCreateItem = false;
      this.edit = !this.edit;
      this.save.emit(this.items);
    }
  }

   createNew(): void {
    if (!this.tempEditnewCreateItem) {
      this.currentForm.reset();
      this.loaded = false;
      this.tempNew = true;
      this.currentForm.get('current')?.valueChanges.subscribe(change => {
        if(change){
          this.currentForm.get('endDate').reset();
          this.currentForm.get('endDate').disable();
        }else{
          this.currentForm.get('endDate').enable();
        }
      }
    )
    }
  }

  saveNew(): void {
    let tmp = new Object;
    for(let setting of this.fieldSettings){
      if (setting.name === 'candidateAttachment') {
        tmp[setting.name] = this.currentForm.get(setting.name).value;
        for (let attSet of this.attachemntFS) {
          tmp[setting.name][attSet.name] = this.currentForm.get(setting.name + '.' + attSet.name).value;
        }
        tmp[setting.name]["tenantId"] = this.user.tenantID;
      }else{
        tmp[setting.name] = this.currentForm.get(setting.name).value;
      }
     }
    tmp['edit'] = false;
    this.items.push(tmp);

    this.tempNew = false;
    this.save.emit(this.items);
  }

  closeCreatenew(): void {
    this.loaded = true;
    this.tempNew = false;
    this.currentForm.reset();
  }

  removeItem(index: number, panel: MatExpansionPanel){
    this.tempPanel = panel;
    if (index > -1) {
      let assType: string;

      switch (this.current) {
        case 'skillName':
          assType = 'skill';
          break;
        case 'language':
          assType = 'language';
          break;
        case 'university':
          assType = 'university';
          break;
        case 'highSchool':
          assType = 'highSchool';
          break;
        case 'workExperience':
          assType = 'workExperience';
          break;
        default:
          assType = 'unknow';
          break;
      }

      const item = this.items[index] as any;

      this.delete.emit({id: item.id, assType: assType, index: index})
    }
  }

  removeResponseHandler(index: number){
    this.translate.get('shared.cancel', { value: '' }).subscribe((resp: string) => {
      this.cancel = resp;
    });
    this.items.splice(index, 1);
        let statusMsg;
        this.translate.get('shared.deleted', { value: '' }).subscribe((resp: string) => {
          statusMsg = resp;
        });
        this.snackBar.open(statusMsg, this.cancel, {
          duration: 3000,
          panelClass: ['snackbar-formater']
        });
        this.tempEditnewCreateItem = false;
        this.edit = false;
        this.tempPanel.expanded = false;
        this.hideExpansionIcons = false;
  }

  errorHandler(){
    this.translate.get('shared.cancel', { value: '' }).subscribe((resp: string) => {
      this.cancel = resp;
    });
    let statusMsg;
    this.translate.get('shared.cards.failedToRemoveItem', { value: '' }).subscribe((resp: string) => {
      statusMsg = resp;
    });
    this.snackBar.open(statusMsg, this.cancel, {
      duration: 3000,
      panelClass: ['snackbar-formater']
    });
  }

}
