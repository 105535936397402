<div class="d-flex justify-content-between mb-3">
    <h3 class="field-headers">{{ title | translate}}</h3>
    <div>
        <mat-icon class="edit" (click)="startEditing()" class="ml-2 mr-2" *ngIf="!edit">edit
        </mat-icon>
        <mat-icon *ngIf="edit" (click)="save()" class="icon-save">save
        </mat-icon>
        <mat-icon class="icon-close" *ngIf="edit" (click)="cancelEdit()">cancel
        </mat-icon>
    </div>
</div>
