import { setting } from './setting.model';

export const universityFieldSettings: Array<setting> = [
    {name: 'universityName', type: 'string', label:'stepperForm.highSchool.hsNAme', placeholder: 'stepperForm.examplePlaceHolders.hsNAmeP'},
    {name: 'fieldOfStudy', type: 'string', label:'stepperForm.highSchool.hsField', placeholder: 'stepperForm.examplePlaceHolders.degree'},
    {name: 'city', type: 'string', label:'stepperForm.personalInfo.city', placeholder: 'stepperForm.examplePlaceHolders.city'},
    {name: 'country', type: 'string', label:'stepperForm.personalInfo.country', placeholder: 'stepperForm.examplePlaceHolders.country'},
    {name: 'startDate', type: 'date', label:'stepperForm.highSchool.startDate', placeholder: 'Ex. 4/12/1995'},
    {name: 'endDate', type: 'date', label:'stepperForm.highSchool.endDate', placeholder: 'Ex. 4/12/1995'},
    {name: 'current', type: 'boolean', label:'stepperForm.university.current', placeholder: 'Ex. 4/12/1995'},
    {name: 'edit', type: 'boolean'},
    { name: 'candidateAttachment', fileName: 'fileName', fileType: 'fileType', fileID: 'fileID'}
]
