import { Component, OnInit } from '@angular/core';
import { CardItemComponent } from '../card-item.component';

@Component({
  selector: 'app-card-item-boolean',
  templateUrl: './card-item-boolean.component.html',
  styleUrls: ['./card-item-boolean.component.scss', '../card-item.component.scss']
})
export class CardItemBooleanComponent extends CardItemComponent{

  constructor() {
    super()
   }

}
