import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BodyAttachmentResponse } from 'src/app/shared/models/responses/body-attachment-response.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  uploadInternalCandidateFileURL = 'upload-internal-candidate-file';
  checkPermissionsURL = 'check-attachment-permissions';
  downloadCandidateAttachmentURL = 'get-candidate-attachments-id';

  constructor(
    private apiService: ApiService,
    private http: HttpClient,

  ) { }

  sendCandidateAttachment(data: Blob, fileType: string, fileName: string): Observable<BodyAttachmentResponse> {
    const httpHeaders = this.apiService.getHTTPHeaders();
    let queryParams = new HttpParams();
    queryParams = queryParams.append('filetype', fileType);
    queryParams = queryParams.append('fileName', fileName);

    return this.http.post<BodyAttachmentResponse>(environment.apiUrl +
      this.uploadInternalCandidateFileURL, data, { headers: httpHeaders, params: queryParams });
  }

  checkAttachmentPermissions(entityID: number, detailType: string): Observable<boolean>{
    const httpHeaders = this.apiService.getHTTPHeaders();
    let queryParams = new HttpParams();
    queryParams = queryParams.append('entityModel', detailType);
    queryParams = queryParams.append('entityID', entityID);

    return this.http.get<boolean>(environment.apiUrl +
      this.checkPermissionsURL, { headers: httpHeaders, params: queryParams })
  }
  downloadCandidateAttachment(id: string): Observable<any>{
    const httpHeaders = this.apiService.getHTTPHeaders();
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', id);

    return this.http.get<Response>(environment.apiUrl +
      this.downloadCandidateAttachmentURL, { headers: httpHeaders, observe: 'response', params: queryParams, responseType: 'blob' as 'json' })
  }

  downloadResponseFile(response: Response): void {
    const dataType = response.body['type'];
    const binaryData = [];
    binaryData.push(response.body);
    const objectUrl = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));

    const fileName = response.headers.get('Content-Disposition').split(';filename=')[1]
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }
}
