<div class="inner-card-section">
<div class="new-email d-flex flex-column" [formGroup]="form">

    <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'shared.google.event.title' | translate}}</mat-label>
        <input formControlName="title" matInput matNativeControl placeholder="Some event..">
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'shared.google.event.description' | translate}}</mat-label>
        <input formControlName="description" matInput placeholder="Subject..">
    </mat-form-field>

    <div class="d-flex justify-content-between two-field-row">
        <div>
            <mat-form-field appearance="outline" class="mr-3">
                <mat-label>{{'shared.startDate' | translate}}</mat-label>

                <input matInput [ngxMatDatetimePicker]="picker" placeholder="Meeting start" formControlName="start">

                <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>

                <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline" class="mr-3">
                <mat-label>{{'shared.endDate' | translate}}</mat-label>
                <input matInput [ngxMatDatetimePicker]="picker2" placeholder="Meeting end" formControlName="end">

                <mat-datepicker-toggle matSuffix [for]="$any(picker2)"></mat-datepicker-toggle>

                <ngx-mat-datetime-picker #picker2></ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
    </div>

    <div class="d-flex">

        <button mat-button  class="success-button mt-3" type="button" (click)="createEvent()">
            <mat-label>{{'shared.google.event.create' | translate}}</mat-label>
        </button>
        <button mat-button  class="base-background-button mt-3" type="button" (click)="cancel()">
            <mat-label>{{'shared.google.event.cancel' | translate}}</mat-label>
        </button>
    </div>
</div>
</div>