import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { StepperService } from '../../../../../../../apps/talentway-fe/src/app/views/stepper/stepper.service';
import { Attachment } from '../../../models/attachment.model';
import { DashboardService } from '../../../../../../../apps/talentway-fe/src/app/views/dashboard/dashboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AttachmentService } from "../../../../../../../apps/talentway-fe-candidate/src/app/shared/services/attachment.service";
@Component({
  selector: 'card-item-attachemnts',
  templateUrl: './card-item-attachemnts.component.html',
  styleUrls: ['./card-item-attachemnts.component.scss']
})

export class CardItemAttachemntsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() type: string;
  @Input() edit: boolean;
  @Input() item: Attachment
  @Input() temNew: boolean;
  @Input() clickableAttachments=false;

  toFile!: any;
  oldHSAtt: Attachment
  deletedAtt= false;
  cancel: string;
  msg: string;

  constructor(
    private translate: TranslateService,
    private stepperService: StepperService,
    private dashboardService: DashboardService,
    private snackBar: MatSnackBar,
    private attachmentService: AttachmentService,
  ) {}

  ngOnInit() {
    if (this.temNew && (this.type == 'highSchool' || this.type == 'university')) {
      this.item= new Attachment();

    }
  }

  // TODO delete attachment only after save changes. now delete immediately in deleteAttachment()
  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.edit.previousValue === true && changes.edit.currentValue === false && this.deletedAtt) {
  //     this.item = this.oldHSAtt;
  //     console.log(this.item);

  //   }
  // }

  // Upload file to S3 logics
  addedNewFileToUpload(event: any, form: any, eduType: string): void {
    this.toFile = event.target.files;
    const fileToAWS = this.toFile.item(0);
    fileToAWS.arrayBuffer().then((arrayBuffer: any) => {
      const blob = new Blob([new Uint8Array(arrayBuffer)], { type: fileToAWS.type });
      this.sendUploadedFileToS3(blob, form, fileToAWS, eduType);
    });
  }

  sendUploadedFileToS3(blob: Blob, form: any, file: File, eduType: string): void {

    this.stepperService.sendCVAttachment(blob, file.type).subscribe(res => {
      const s3url = res.body;

      form.get('candidateAttachment.url')?.setValue(s3url);
      form.get('candidateAttachment.fileType')?.setValue(file.type);
      form.get('candidateAttachment.fileName')?.setValue(file.name);
      form.get('candidateAttachment.edit')?.setValue(false);

      if (eduType === 'highSchool') {
        form.get('candidateAttachment.type')?.setValue('High School Certificate');
        this.item.type = 'High School Certificate';
      } else if (eduType === 'university') {
        form.get('candidateAttachment.type')?.setValue('University Certificate');
        this.item.type = 'University Certificate'
      }

      this.item.url = s3url;
      this.item.fileName = file.name;
      this.item.fileType = file.type;
      this.item.id = 0;
      var user = JSON.parse(localStorage.getItem('user'));
      this.item.tenantId = user.tenantId;

    }, error => {
      this.translate.get('shared.cancel', { value: '' }).subscribe((resp: string) => {
        this.snackBar.open(`${error}`, resp, {
          duration: 3000,
          panelClass: ['snackbar-formater']
        });
      });
    }
    );
  }

  deleteAttachment(form: any): void {

    this.oldHSAtt= this.item;
    this.dashboardService.deleteAttachment(this.item.id.toString()).subscribe(res => {

      const newAtt = new Attachment();
      newAtt.url = '',
      newAtt.fileType = '',
      newAtt.type = '',
      newAtt.fileName = '',
      form.candidateAttachment = newAtt;
      form.get('candidateAttachment')?.setValue(newAtt)
      this.item.id = 0;
      this.item = new Attachment();
      this.deletedAtt = true;

    },
    error => {
      this.translate.get('shared.cancel', { value: '' }).subscribe((resp: string) => {
        this.snackBar.open(`${error}`, resp, {
          duration: 3000,
          panelClass: ['snackbar-formater']
        });
      });
    })
  }

  getLabel():string {
    return 'stepperForm.fileUpload'
  }

  downloadAttachment(id: number) {
    this.attachmentService.downloadCandidateAttachment(id.toString()).subscribe(
      (response: Response) => {
        this.attachmentService.downloadResponseFile(response)
      },
      error => {
        this.translate.get('shared.cancel', { value: '' }).subscribe((resp: string) => {
          this.cancel = resp;
        });
        this.translate.get('stepperForm.downloadFileErr', { value: '' }).subscribe((resp: string) => {
          this.msg = resp;
        });
        this.snackBar.open(this.msg, this.cancel, {
          duration: 3000,
          panelClass: ['snackbar-formater']
        });
      }
    )
  }

}
