import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslateLanguageService {
  languageValue = new BehaviorSubject(this.language);

  set language(value) {
    this.languageValue.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('language', value);
  }

  // tslint:disable-next-line:typedef
  get language() {
    return localStorage.getItem('language');
  }
}
