import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { FinalLongCvModel, LongCvResponse } from '@talentway/shared';
import { PersonalData } from '../../shared/models/registration-questions-personal-data.model';
import { AllCVOptionsFromDBResponse } from '../../shared/models/responses/cv-optionsResponse.model';
import { BodyNullResponse } from '../../shared/models/responses/body-null-response.model';
import { BodyStringResponse } from '../../shared/models/responses/body-string-response.model';
import { UserFromBE } from '@talentway/shared';

@Injectable({
  providedIn: 'root'
})
export class StepperService {

  questionsData = 'create-questions';
  requestInfoURL = 'request-info';
  cvFormOptions = 'cv-options';
  cvFormDataToSend = 'create-cv';
  cvUploadFile = 'upload-file';
  fake = 'asdf';
  checkRegistrationURL = 'registered-check';
  getCvURL = 'cv';

  constructor(
    private http: HttpClient
  ) { }


  private getHTTPHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', 'application/json');
  }



  requestInfo(field: string, id: string, language: string): Observable<BodyNullResponse> {
    const httpHeaders = this.getHTTPHeaders();
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', id);
    queryParams = queryParams.append('field', field);
    queryParams = queryParams.append('language', language);

    return this.http.post<BodyNullResponse>(environment.apiUrl + this.requestInfoURL, null,
    { headers: httpHeaders, params: queryParams });
  }

  sendQuestionsData(data: UserFromBE, jobId: number): Observable<BodyNullResponse> {
    const httpHeaders = this.getHTTPHeaders();
    let queryParams = new HttpParams();
    queryParams = queryParams.append('jobId', jobId ? jobId : '');

    return this.http.post<BodyNullResponse>(environment.apiUrl + this.questionsData, JSON.stringify(data),
    { headers: httpHeaders, params: queryParams });
  }

  getCVOptions(): Observable<AllCVOptionsFromDBResponse> {
    return this.http.get<AllCVOptionsFromDBResponse>(environment.apiUrl + this.cvFormOptions);
  }

  sendLongCVData(data: FinalLongCvModel): Observable<BodyNullResponse> {
    const httpHeaders = this.getHTTPHeaders();
    return this.http.post<BodyNullResponse>(environment.apiUrl +
      this.cvFormDataToSend, JSON.stringify(data), {headers: httpHeaders});
  }

  sendCVAttachment(data: Blob, fileTYpe: string): Observable<BodyStringResponse> {
    const httpHeaders = this.getHTTPHeaders();
    let queryParams = new HttpParams();
    queryParams = queryParams.append('filetype', fileTYpe);

    return this.http.post<BodyStringResponse>(environment.apiUrl +
      this.cvUploadFile, data, { headers: httpHeaders, params: queryParams});
  }

  getCV(id: number): Observable<LongCvResponse> {
    const httpHeaders = this.getHTTPHeaders();
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', id);

    return this.http.get<LongCvResponse>(environment.apiUrl +
      this.getCvURL, { headers: httpHeaders, params: queryParams});
  }

  registeredCheck(hash: string): Observable<any>{
    let queryParams = new HttpParams();
    queryParams = queryParams.append('hash', hash);

    return this.http.get<any>(environment.apiUrl + this.checkRegistrationURL, {
      params: queryParams
    });
  }

}
