import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CardItemComponent } from '../card-item.component';

@Component({
  selector: 'app-card-item-select',
  templateUrl: './card-item-select.component.html',
  styleUrls: ['./card-item-select.component.scss', '../card-item.component.scss']
})
export class CardItemSelectComponent extends CardItemComponent implements OnInit {
  @Input() values: string[];
  @Input() texts?: string[];
  @Input() toTranslate?: boolean = false;

  combinedArray : {value: string, text: string}[] = []
  displaiedTitle: string
  constructor(
    private translate: TranslateService
  ) {
    super()
   }

  ngOnInit(): void {

    if(this.texts && this.toTranslate){
      const tmp = new Array<string>();
      this.texts.forEach(text =>{
        this.translate.get(text, {value: ''}).subscribe(res =>{
          tmp.push(res);
        })
      })
      this.texts = tmp;
    }
    if(this.texts === undefined){
      this.texts = this.values;
    }
    this.values.forEach((value, index) => this.combinedArray.push({ value: value, text: this.texts[index] }));
    this.displaiedTitle = this.combinedArray.find(item => item.value === this.control.value).text;
  }

}
