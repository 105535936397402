
export class CandidatesByStatusResponse{
    body: CandidatesByStatus[];
    perPage: number;
    total: number;
  }


export class CandidatesByStatus{
    statusName: string;
    id: number;
    hrAssignedJobs: Array<any>
  }
  