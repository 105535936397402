import { TenantAddresses } from './tenant-address.model';
import { HRManager } from './hr-manager.model';
import { HROperative } from './hr-operator.model';


export class FinallFirmRegisterModel {
    id: number;
    createdAt: string;
    deletedAt: string;
    name?: string;
    logo: string;
    active: boolean;
    tenantType?: string;
    companyID: string;
    taxID: string;
    vat: string;
    inRegister?: string;
    companyRepresentativeName?: string;
    companyRepresentativeEmail?: string;
    employeesNumber?: string;
    tenantCharacteristic?: string;
    tenantBenefits?: string[];
    tenantAddresses: TenantAddresses[];
}

export class FinallFirmUpdateModel {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  name?: string;
  logo: string;
  active: boolean;
  tenantType?: string;
  companyID: string;
  taxID: string;
  vat: string;
  inRegister?: string;
  companyRepresentativeName?: string;
  companyRepresentativeEmail?: string;
  employeesNumber?: string;
  tenantCharacteristic?: string;
  tenantBenefits?: string[];
  tenantAddresses: TenantAddresses[];
}
