import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BodyNullResponse } from 'src/app/shared/models/responses/body-null-response.model';
import { environment } from 'src/environments/environment';
import { FinallCreateJobOffer } from 'src/app/shared/models/dashboard/job-offer/finall-job-offer.model';
import { JobOfferResponse } from 'src/app/shared/models/responses/job-offer-response.model';
import { FinalCandidateHRCvrModel } from 'src/app/shared/models/dashboard/create-candidate/finall-candidate.model';
import { NotesModel } from 'src/app/shared/models/dashboard/candidate-detail/notes.model';
import { NotesBEModel } from 'src/app/shared/models/dashboard/candidate-detail/notes-be.model';
import { FinallFirmRegisterModel } from 'src/app/shared/models/firm-register/firm-register-final.model';
import { UserFromBE, FinallUserInvitationsModel, FinallUserResendInvitationsModel } from '@talentway/shared'
import { BodyAttachmentResponse } from 'src/app/shared/models/responses/body-attachment-response.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  createCVHR = 'create-cv-hr';
  updateCVHR = 'update-hr-cv';
  getCandidateDetailByIdURL = 'get-hr-cv-id';
  updateCandidateStatusURL = 'update-hr-cv-status';
  deleteAssociateCandidateURL = 'delete-associations-hr-cv';
  deleteItemURL = 'delete-item-job-offer';
  getJobOfferByIdURL = 'get-job-offer-id';
  getAttachmentsByIdURL = 'get-attachments';
  deleteAttachmentURL = 'delete-hr-attachment';
  deleteAttachmentByURL = 'delete-attachment-by-url';
  getJobOfferByCandidateURL = 'get-job-offer-candidate';
  getCandidateByJobOfferURL = 'get-candidate-job-offer';
  createNoteURL = 'create-note';
  getNotesIDURL = 'get-notes-id';
  getNotesURL = 'get-notes';
  updateNotesURL = 'update-notes';
  deleteNotesURL = 'delete-notes';
  getJobOffersURL = 'job-offers';
  getCandidatesURL = 'hr-cv';
  getAuditURL = 'get-audit';
  createJobOffer = 'create-job-offer';
  editJobOffer = 'update-job-offer';
  getEditJobOffer = 'get-job-offer-id';
  getEditJobOfferToEdit = 'get-job-offer-id-to-edit';
  updateJobOfferStatusURL = 'update-job-offer-status';
  assignJobToCandidateURL = 'assign-job';
  deleteJobFromCandidateURL = 'delete-assigned-job';
  getAssignedJobsAndCandidatesURL = 'get-candidates-to-job-offers';
  getCompanyDataByIdURL = 'get-tenant-id';
  updateCompanyURL = 'update-tenant';
  sendInvitation = 'send-invitations';
  updateUserRoleURL = 'update-user-role';
  resendInvitationURL = 'resend-invitation';
  getTenantUsersURL = 'get-tenant-users';
  updateTenantsUserStatusURL = 'update-tenant-user-status';
  deleteTenantsUserURL = 'delete-tenant-user';
  resendInvitationFOrTenantsUserURL = 'resend-invitation-for-tenant-user';
  hrDashboardURL = 'dashboard';
  hrDashboarDiversitydURL = 'dashboard-diversity';
  hrDashboarAverageURL = 'average-time-to-hire';
  getStatusesToJobsURL = 'get-statuses-to-jobs';
  getUserDataURL = 'get-user-data';
  updateUserURL = 'update-hr-user';

constructor(
    private http: HttpClient
  ) { }


  private getHTTPHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', 'application/json');
  }

  getJobOffers(limit?: string, offset?: string, dateFrom?: string, dateTo?: string, filter?: string,
               statuses?: string, sort?: string, sortColumn?: string, blacklist?: string[]): Observable<any> {
    let queryParams = new HttpParams();

    queryParams = queryParams.append('dateTo', dateTo ? dateTo : '');
    queryParams = queryParams.append('dateFrom', dateFrom ? dateFrom : '');
    queryParams = queryParams.append('filter', filter ? filter : '');
    queryParams = queryParams.append('limit', limit ? limit : '');
    queryParams = queryParams.append('offset', offset ? offset : '');
    queryParams = queryParams.append('statuses', statuses ? statuses : '');
    queryParams = queryParams.append('sort', sort ? sort : '' );
    queryParams = queryParams.append('sortColumn', sortColumn ? sortColumn : '');
    queryParams = queryParams.append('blacklist', JSON.stringify(blacklist));

    return this.http.get<any>(environment.apiUrl + this.getJobOffersURL, {params: queryParams});
  }

  getCandidates(limit?: string, offset?: string, dateFrom?: string, dateTo?: string, filter?: string,
                statuses?: string,  sort?: string, sortColumn?: string, blacklist?: string[]): Observable<any> {
    let queryParams = new HttpParams();

    queryParams = queryParams.append('dateTo', dateTo ? dateTo : '');
    queryParams = queryParams.append('dateFrom', dateFrom ? dateFrom : '');
    queryParams = queryParams.append('filter', filter ? filter : '');
    queryParams = queryParams.append('limit', limit ? limit : '');
    queryParams = queryParams.append('offset', offset ? offset : '');
    queryParams = queryParams.append('statuses', statuses ? statuses : '');
    queryParams = queryParams.append('sort', sort ? sort : 'asc' );
    queryParams = queryParams.append('sortColumn', sortColumn ? sortColumn : 'id');
    queryParams = queryParams.append('blacklist', JSON.stringify(blacklist));

    return this.http.get<any>(environment.apiUrl + this.getCandidatesURL, {params: queryParams});
  }

  sendNewJobOfferData(data: FinallCreateJobOffer): Observable<BodyNullResponse> {
    const httpHeaders = this.getHTTPHeaders();
    return this.http.post<BodyNullResponse>(environment.apiUrl + this.createJobOffer, JSON.stringify(data), { headers: httpHeaders });
  }

  sendEditJobOfferData(data: any, group: string, id: number | string): Observable<BodyNullResponse> {
    const httpHeaders = this.getHTTPHeaders();
    let queryParams = new HttpParams();

    queryParams = queryParams.append('group', group);
    queryParams = queryParams.append('id', id);
    return this.http.put<BodyNullResponse>(environment.apiUrl + this.editJobOffer,
      JSON.stringify(data), { headers: httpHeaders, params: queryParams });

  }

  getEditJobOfferDataByID(id?: number): Observable<JobOfferResponse> {
    let queryParams = new HttpParams();
    const httpHeaders = this.getHTTPHeaders();
    queryParams = queryParams.append('id', id ? id.toString() : '');

    return this.http.get<JobOfferResponse>(environment.apiUrl + this.getEditJobOfferToEdit, {
      params: queryParams
    });
  }

  assignJobToCandidate(candidateId?: string, jobId?: string): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('candidateId', candidateId ? candidateId : '');
    queryParams = queryParams.append('jobId', jobId ? jobId : '');

    return this.http.get<any>(environment.apiUrl + this.assignJobToCandidateURL, {
      params: queryParams
    });
  }

  deleteAssignedJobFromCandidate(candidateId?: string, jobId?: string): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('candidateId', candidateId ? candidateId : '');
    queryParams = queryParams.append('jobId', jobId ? jobId : '');

    return this.http.get<any>(environment.apiUrl + this.deleteJobFromCandidateURL, {
      params: queryParams
    });
  }

  deleteAttachment(attachmentId: string): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('attachmentId', attachmentId);

    return this.http.delete<any>(environment.apiUrl + this.deleteAttachmentURL, {
      params: queryParams
    });
  }

  deleteAttachmentByUrl(attachmentUrl: string): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('attachmentUrl', attachmentUrl);

    return this.http.delete<any>(environment.apiUrl + this.deleteAttachmentByURL, {
      params: queryParams
    });
  }

  getJobOfferById(id?: number): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', id ? id : '');

    return this.http.get<any>(environment.apiUrl + this.getJobOfferByIdURL, {
      params: queryParams
    });
  }

  getAttachmentsByJobId(model: string, id: number): Observable<BodyAttachmentResponse> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('entityID', id);
    queryParams = queryParams.append('entityModel', model);

    return this.http.get<BodyAttachmentResponse>(environment.apiUrl + this.getAttachmentsByIdURL, {
      params: queryParams
    });
  }

  updateJobOfferStatusById(id?: number, status?: string): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', id ? id : '');
    queryParams = queryParams.append('status', status ? status : '');

    return this.http.get<any>(environment.apiUrl + this.updateJobOfferStatusURL, {
      params: queryParams
    });
  }

  updateCandidateStatusById(id?: number, status?: string): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', id ? id : '');
    queryParams = queryParams.append('status', status ? status : '');

    return this.http.get<any>(environment.apiUrl + this.updateCandidateStatusURL, {
      params: queryParams
    });
  }

  createNewCandidateCVHR(data: FinalCandidateHRCvrModel): Observable<any> {
    const httpHeaders = this.getHTTPHeaders();
    return this.http.post<any>(environment.apiUrl + this.createCVHR, JSON.stringify(data), { headers: httpHeaders });
  }

  getCandidateDetailById(id?: number): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', id ? id : '');

    return this.http.get<any>(environment.apiUrl + this.getCandidateDetailByIdURL, {
      params: queryParams
    });
  }

  sendEditCandidateData(data: FinallCreateJobOffer): Observable<BodyNullResponse> {
    const httpHeaders = this.getHTTPHeaders();
    return this.http.put<BodyNullResponse>(environment.apiUrl + this.updateCVHR, data, { headers: httpHeaders });
  }

  createNewNote(data: NotesModel): Observable<any> {
    const httpHeaders = this.getHTTPHeaders();
    return this.http.post<any>(environment.apiUrl + this.createNoteURL, JSON.stringify(data), { headers: httpHeaders });
  }

  getAllNotes(limit?: string, offset?: string, entityIDs?: string, noteType?: string, dateFrom?: string, dateTo?: string, filter?: string,
              ): Observable<any> {
    let queryParams = new HttpParams();

    queryParams = queryParams.append('limit', limit ? limit : '');
    queryParams = queryParams.append('offset', offset ? offset : '');
    queryParams = queryParams.append('dateFrom', dateFrom ? dateFrom : '');
    queryParams = queryParams.append('dateTo', dateTo ? dateTo : '');
    queryParams = queryParams.append('filter', filter ? filter : '');
    queryParams = queryParams.append('entityIDs', entityIDs ? entityIDs : '');
    queryParams = queryParams.append('noteType', noteType ? noteType : '');

    return this.http.get<any>(environment.apiUrl + this.getNotesURL, { params: queryParams });
  }

  getCandidateNoteById(id?: number): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', id ? id : '');

    return this.http.get<any>(environment.apiUrl + this.getNotesIDURL, {
      params: queryParams
    });
  }

  updateNote(id: number, userId: number, notes: string, entityID: number, type: string,
             createdAt: string, updatedAt: string, deletedAt: string): Observable<any> {
    const note = new NotesBEModel();
    note.id = id;
    note.userID = userId;
    note.note = notes;
    note.entityID = entityID;
    note.type = type;
    note.createdAt = createdAt;
    note.updatedAt = updatedAt;
    note.deletedAt = deletedAt;

    return this.http.put<any>(environment.apiUrl + this.updateNotesURL, JSON.stringify(note) );
  }

  deleteNote(id: number, userId: number, note: string, entityID: number, type: string,
             createdAt: string, updatedAt: string, deletedAt: string): Observable<any> {

    return this.http.delete<any>(environment.apiUrl + this.deleteNotesURL, { body: { id, userId, note,
      entityID, type, createdAt, updatedAt, deletedAt
    }});
  }


  sendInvitaions(data: FinallUserInvitationsModel): Observable<BodyNullResponse> {
    const httpHeaders = this.getHTTPHeaders();
    let queryParams = new HttpParams();
    queryParams = queryParams.append('email', data ? data.email : '');
    queryParams = queryParams.append('role', data ? data.role : '');
    queryParams = queryParams.append('language', data ? data.language : '');
    return this.http.post<BodyNullResponse>(environment.apiUrl + this.sendInvitation, JSON.stringify(data),
    { headers: httpHeaders, params: queryParams});
  }

  updateUserRole(roleIdFrom: number, roleIdTo: number, data: UserFromBE): Observable<BodyNullResponse> {
    const httpHeaders = this.getHTTPHeaders();
    let queryParams = new HttpParams();
    queryParams = queryParams.append('userId', data ? data.id : '');
    queryParams = queryParams.append('roleIdTo', data ? roleIdTo : '');
    queryParams = queryParams.append('roleIdFrom', data ? roleIdFrom : '');

    return this.http.put<BodyNullResponse>(environment.apiUrl + this.updateUserRoleURL, JSON.stringify(data), {
     headers: httpHeaders,
      params: queryParams
    });
  }

  getTenantUsers(id: number): Observable<BodyNullResponse> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', id ? id : '');

    return this.http.get<BodyNullResponse>(environment.apiUrl + this.getTenantUsersURL, {
      params: queryParams
    });
  }

  updateTenantsUserStatusById(id?: number, status?: string): Observable<BodyNullResponse> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', id ? id : '');
    queryParams = queryParams.append('status', status ? status : '');

    return this.http.get<BodyNullResponse>(environment.apiUrl + this.updateTenantsUserStatusURL, {
      params: queryParams
    });
  }

  deleteTenantsUserById(id: number): Observable<BodyNullResponse> {

    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', id ? id : '');
    return this.http.delete<BodyNullResponse>(environment.apiUrl + this.deleteTenantsUserURL, {
      params: queryParams
    });
  }

  resendInvitaionForTenantUser(data: FinallUserResendInvitationsModel): Observable<BodyNullResponse> {
    const httpHeaders = this.getHTTPHeaders();
    let queryParams = new HttpParams();
    queryParams = queryParams.append('email', data ? data.email : '');
    queryParams = queryParams.append('inviteHash', data ? data.inviteHash : '');
    queryParams = queryParams.append('language', data ? data.language : '');
    return this.http.post<BodyNullResponse>(environment.apiUrl + this.resendInvitationURL, JSON.stringify(data),
      { headers: httpHeaders, params: queryParams });
  }

  getAudit(limit?: string, offset?: string, dateFrom?: string, dateTo?: string,
           userIDs?: string, entityIDs?: string, auditType?: string, auditModel?: string): Observable<any>{
    let queryParams = new HttpParams();
    queryParams = queryParams.append('limit', limit ? limit : '0');
    queryParams = queryParams.append('offset', offset ? offset : '0');
    queryParams = queryParams.append('dateFrom', dateFrom ? dateFrom : '');
    queryParams = queryParams.append('dateTo', dateTo ? dateTo : '');
    queryParams = queryParams.append('userIDs', userIDs ? userIDs : '');
    queryParams = queryParams.append('entityIDs', entityIDs ? entityIDs : '');
    queryParams = queryParams.append('auditType', auditType ? auditType : '');
    queryParams = queryParams.append('auditModel', auditModel ? auditModel : '');

    return this.http.get<any>(environment.apiUrl + this.getAuditURL, {params: queryParams});
  }

  deleteAssociateCandidate(childID: number, associationType: string): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + this.deleteAssociateCandidateURL, {
      body: {
        childID, associationType
      }
    });
  }

  getAssignedCandidatesAndJobs(jobOfferId?: string, candidateId?: string): Observable<any>{
    let queryParams = new HttpParams();
    queryParams = queryParams.append('jobOfferId', jobOfferId ? jobOfferId : '');
    queryParams = queryParams.append('candidateId', candidateId ? candidateId : '');

    return this.http.get<any>(environment.apiUrl + this.getAssignedJobsAndCandidatesURL, {params: queryParams});
  }

  getCompanyDataById(id:string): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', id ? id : '0');

    return this.http.get<any>(environment.apiUrl + this.getCompanyDataByIdURL, {
      params: queryParams
    });
  }

  updateCompany(data: FinallFirmRegisterModel): Observable<FinallFirmRegisterModel> {
    const companyUpdate = new FinallFirmRegisterModel();
    companyUpdate.id = data.id;
    companyUpdate.createdAt = data.createdAt;
    companyUpdate.deletedAt = data.deletedAt;
    companyUpdate.name = data.name;
    companyUpdate.logo = data.logo;
    companyUpdate.active = data.active;
    companyUpdate.tenantType = data.tenantType;
    companyUpdate.companyID = data.companyID;
    companyUpdate.taxID = data.taxID;
    companyUpdate.vat = data.vat;
    companyUpdate.inRegister = data.inRegister;
    companyUpdate.companyRepresentativeName = data.companyRepresentativeName;
    companyUpdate.companyRepresentativeEmail = data.companyRepresentativeEmail;
    companyUpdate.employeesNumber = data.employeesNumber;
    companyUpdate.tenantCharacteristic = data.tenantCharacteristic;
    companyUpdate.tenantBenefits = data.tenantBenefits;
    companyUpdate.tenantAddresses = data.tenantAddresses;
    return this.http.put<any>(environment.apiUrl + this.updateCompanyURL, JSON.stringify(companyUpdate));
  }

  deleteItem(item: string, id: number): Observable<BodyNullResponse>{
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', id);
    queryParams = queryParams.append('item', item);
    return this.http.delete<any>(environment.apiUrl + this.deleteItemURL, {
      params: queryParams
    });
  }
  getHrChartsData(dateFrom?: string, dateTo?: string, period?: string): Observable<any> {
    let queryParams = new HttpParams();

    queryParams = queryParams.append('dateTo', dateTo ? dateTo : '');
    queryParams = queryParams.append('dateFrom', dateFrom ? dateFrom : '');
    queryParams = queryParams.append('period', period ? period : '');

    return this.http.get<any>(environment.apiUrl + this.hrDashboardURL, { params: queryParams });
  }

  getHrChartsData2(dateFrom?: string, dateTo?: string, period?: string): Observable<any> {
    let queryParams = new HttpParams();

    queryParams = queryParams.append('dateTo', dateTo ? dateTo : '');
    queryParams = queryParams.append('dateFrom', dateFrom ? dateFrom : '');
    queryParams = queryParams.append('period', period ? period : '');

    return this.http.get<any>(environment.apiUrl + 'dashboard-statuses-to-jobs', { params: queryParams });
  }

  getHrDiversityChartsData(dateFrom?: string, dateTo?: string, period?: string): Observable<any> {
    let queryParams = new HttpParams();

    queryParams = queryParams.append('dateTo', dateTo ? dateTo : '');
    queryParams = queryParams.append('dateFrom', dateFrom ? dateFrom : '');
    queryParams = queryParams.append('period', period ? period : '');

    return this.http.get<any>(environment.apiUrl + this.hrDashboarDiversitydURL, { params: queryParams });
  }

  getHrAverageChartsData(dateFrom?: string, dateTo?: string, period?: string): Observable<any> {
    let queryParams = new HttpParams();

    queryParams = queryParams.append('dateTo', dateTo ? dateTo : '');
    queryParams = queryParams.append('dateFrom', dateFrom ? dateFrom : '');

    return this.http.get<any>(environment.apiUrl + this.hrDashboarAverageURL, { params: queryParams });
  }

  getHrStatusesToJobsData(): Observable<any> {
    let queryParams = new HttpParams();

    return this.http.get<any>(environment.apiUrl + this.getStatusesToJobsURL, { params: queryParams });
  }

  getUserData(): Observable<any> {
    let queryParams = new HttpParams();
    return this.http.get<any>(environment.apiUrl + this.getUserDataURL, { params: queryParams });
  }

  updateUser(data: UserFromBE): Observable<any> {
    return this.http.put<any>(environment.apiUrl + this.updateUserURL, JSON.stringify(data));
  }

}
