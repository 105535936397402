<div class="field-body d-flex mt-3">

    <p class="rightHeadings">{{ title | translate}}:</p>
    <div *ngIf="!edit">
        <div [innerHTML]="control.value"></div>
    </div>
    <div *ngIf="edit" class="w-100 rich-text-content">

        <div class="rich-text">
            <div>
                <div class="NgxEditor__Wrapper">
                    <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
                    <ngx-editor [editor]="editor" [(ngModel)]="html" [formControl]="control"
                        placeholder="{{placeholder !== undefined ? (placeholder | translate) : ''}}" class="w-100"></ngx-editor>
                </div>
            </div>
            <div *ngIf="control?.touched && control?.invalid" class="alert alert-danger w-100">
                <div *ngIf="control?.errors['required']">
                    {{'shared.fieldError' | translate}}
                </div>
            </div>
        </div>



    </div>
</div>