<div [formGroup]="currentForm">
    <div class="title d-flex align-items-center flex-row mb-3 justify-content-between">
        <div class="d-flex">
            <h3 class="title"> {{ title | translate}}</h3>
            <mat-icon *ngIf="!tempNew && !tempEditnewCreateItem" (click)="createNew()" class="cursor-pointer add-icon">add
            </mat-icon>
            <mat-icon *ngIf="tempNew" (click)="closeCreatenew()" class="cursor-pointer add-icon">close
            </mat-icon>
        </div>
        <div>
            <mat-icon  (click)="requestCandidateInfo(this.current)" class="cursor-pointer">mail</mat-icon>
        </div>
    </div>
    <div *ngIf="tempNew" class="inner-card-section new-item">
        <div class="d-flex flex-column w-100">
            <div *ngFor="let setting of fieldSettings">

                <mat-form-field class="w-100" *ngIf="setting.type === 'string'" appearance="outline">
                    <mat-label>{{ setting.label | translate}}</mat-label>
                    <input [formControlName]="setting.name" matInput matNativeControl
                        placeholder="{{ setting.placeholder | translate}}">
                </mat-form-field>


                <mat-form-field class="w-100" *ngIf="setting.type === 'date'" appearance="outline">
                    <mat-label>{{ setting.label | translate}}</mat-label>
                    <input [matDatepicker]="dp1" [formControlName]="setting.name" placeholder="Ex. 4/12/1995" matInput>
                    <mat-datepicker-toggle [for]="dp1" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #dp1 panelClass="example-month-picker">
                    </mat-datepicker>
                </mat-form-field>


                <div *ngIf="setting.name === 'current'" class="d-flex align-start ml-3">
                    <mat-slide-toggle formControlName="current" class="cursor-pointer">
                        {{'dashboard.candidateDetail.currentlyStudying' | translate}}</mat-slide-toggle>
                </div>

            </div>
            <card-item-attachemnts
            [type]="current"
            [form]="currentForm"
            [edit]="true"
            [temNew]="true"
            ></card-item-attachemnts>
        </div>
        <div class="new-item-nav mt-1 mb-1 d-flex justify-content-evenly">
            <button mat-stroked-button type="button" (click)="closeCreatenew()" class="close">{{'shared.close' |
                translate}}</button>
            <button mat-stroked-button type="button" (click)="saveNew()"
                [ngClass]="currentForm.get('highSchoolEducations')?.valid ? 'valid-submit-button' : ''"
                class="save">{{'shared.save' | translate}}</button>
        </div>
    </div>

    <div class="expansion-content">
        <mat-accordion class="w-100" [multi]="true">
            <mat-expansion-panel *ngFor="let item of unchangableItems; let i = index" #panel [hideToggle]="hideExpansionIcons">
                <mat-expansion-panel-header [ngStyle]="{'cursor': !edit ? 'pointer' : 'default'}" #panelH
                    (click)="panelH._toggle()">
                    <mat-panel-title (click)="panelH._toggle()">
                        <span class="w-100">{{item[fieldSettings[0].name]}}</span>
                    </mat-panel-title>
                    <mat-panel-description class="d-flex justify-content-end" (click)="panelH._toggle()">
                        <span  class="w-100 text-right">{{item.startDate
                            | date : 'shortDate'}} - {{ item.current ||item.endDate === null ? 'curent':
                            item.endDate | date : 'shortDate'}}</span>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div *ngFor="let setting of fieldSettings">

                    <div *ngIf="setting.type === 'string'" class="d-flex align-start ml-3">
                        <div class="d-flex w-100">
                            <p>{{ setting.label | translate}}:</p>
                            <span>{{item[setting.name]}}</span>
                        </div>
                    </div>

                    <div *ngIf="setting.name === 'current'" class="d-flex align-start ml-3">

                        <div class="d-flex align-start ml-3">
                            <div class="d-flex w-100">
                                <p>{{'dashboard.candidateDetail.currentlyStudying' | translate}}:</p>
                                <span *ngIf="item.current">{{'shared.yes' | translate}}</span>
                                <span *ngIf="!item.current">{{'shared.no' | translate}}</span>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="setting.type === 'date'" class="d-flex align-start ml-3">
                        <div *ngIf="!(setting.name === 'endDate' && item.current)" class="d-flex w-100">
                            <p>{{ setting.label| translate}}:</p>
                            <span>{{item[setting.name] !== null ? (item[setting.name] | date : 'dd.MM.yyyy') : ""
                                }}</span>
                        </div>
                    </div>

                </div>
                <card-item-attachemnts
                [clickableAttachments]="clickableAtt"
                [type]="current"
                [form]="currentForm"
                [edit]="edit"
                [item]="item.candidateAttachment"></card-item-attachemnts>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion class="w-100" [multi]="!edit ? true : false">
            <mat-expansion-panel *ngFor="let item of items; let i = index" #panel [hideToggle]="hideExpansionIcons">
                <mat-expansion-panel-header [ngStyle]="{'cursor': !edit ? 'pointer' : 'default'}" #panelHa
                    (click)="panelHa._toggle()">
                    <mat-panel-title>
                        <span *ngIf="edit" class="w-100">{{item[fieldSettings[0].name]}}</span>
                        <span *ngIf="!edit" (click)="panelHa._toggle()"
                            class="w-100">{{item[fieldSettings[0].name]}}</span>
                    </mat-panel-title>
                    <mat-panel-description class="d-flex justify-content-end"
                        [ngStyle]="{'margin-right': !edit ? '.5rem' : '0'}">
                        <span *ngIf="!edit" (click)="panelHa._toggle()" class="w-100 text-right">{{item.startDate
                            | date : 'shortDate'}} - {{ item.current ||item.endDate === null ? 'curent':
                            item.endDate | date : 'shortDate'}}</span>
                        <div class="d-flex">
                            <mat-icon *ngIf="!edit || step !== i" (click)="editItem(i, panel)" class="ml-2 mr-2"
                                [ngStyle]="{'cursor': !edit ? 'pointer' : 'default'}"
                               matTooltip="{{'shared.edit' | translate}}">edit</mat-icon>
                            <mat-icon *ngIf="edit && step === i" (click)="removeItem(i, panel)"
                                class="ml-2 mr-2 icon-delete"
                                matTooltip="{{'dashboardSettings.userManagement.delete' | translate}}">
                                delete_forever
                            </mat-icon>
                            <mat-icon *ngIf="edit && step === i" (click)="saveEditItem(i, panel)" class="icon-save"
                                matTooltip="{{'shared.save' | translate}}">save</mat-icon>
                            <mat-icon *ngIf="edit && step === i" (click)="closeEditItem(i, panel); panelHa._toggle()"
                                class="icon-close" matTooltip="{{'shared.cancel' | translate}}" >cancel</mat-icon>
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div *ngFor="let setting of fieldSettings">

                    <div *ngIf="setting.type === 'string'" class="d-flex align-start ml-3">
                        <div *ngIf="!edit" class="d-flex w-100">
                            <p>{{ setting.label | translate}}:</p>
                            <span>{{item[setting.name]}}</span>
                        </div>
                        <mat-form-field appearance="outline" *ngIf="edit" class="w-100">
                            <mat-label>{{ setting.label | translate}}</mat-label>
                            <input [formControlName]="setting.name" matInput matNativeControl
                                placeholder="{{ setting.placeholder | translate}}">
                        </mat-form-field>
                    </div>

                    <div *ngIf="setting.name === 'current'" class="d-flex align-start ml-3">

                        <div class="d-flex align-start ml-3" *ngIf="!edit">
                            <div *ngIf="!edit" class="d-flex w-100">
                                <p>{{'dashboard.candidateDetail.currentlyStudying' | translate}}:</p>
                                <span *ngIf="item.current">{{'shared.yes' | translate}}</span>
                                <span *ngIf="!item.current">{{'shared.no' | translate}}</span>
                            </div>
                        </div>
                        <mat-slide-toggle *ngIf="edit" formControlName="current" class="cursor-pointer">
                            {{'dashboard.candidateDetail.currentlyStudying' | translate}}</mat-slide-toggle>
                    </div>

                    <div *ngIf="setting.type === 'date'" class="d-flex align-start ml-3">
                        <div *ngIf="!edit && !(setting.name === 'endDate' && item.current)" class="d-flex w-100">
                            <p>{{ setting.label| translate}}:</p>
                            <span>{{item[setting.name] !== null ? (item[setting.name] | date : 'dd.MM.yyyy') : ""
                                }}</span>
                        </div>
                        <mat-form-field appearance="outline" *ngIf="edit" class="w-100">
                            <mat-label>{{ setting.label | translate}}</mat-label>
                            <input [matDatepicker]="dp1" [formControlName]="setting.name" placeholder="Ex. 4/12/1995"
                                matInput>
                            <mat-datepicker-toggle [for]="dp1" matSuffix></mat-datepicker-toggle>
                            <mat-datepicker #dp1 panelClass="example-month-picker">
                            </mat-datepicker>
                        </mat-form-field>
                    </div>

                </div>
                <card-item-attachemnts
                [type]="current"
                [form]="currentForm"
                [edit]="edit"
                [item]="item.candidateAttachment"></card-item-attachemnts>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>