export class NotificationClass {
    id?: number;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: null;
    userID!: number;
    entityID!: number;
    type!: string;
    destinationRoute?: string;
    tenantId!: number;
    seen!: boolean;
    text?: string;
    closed?: boolean;
}
