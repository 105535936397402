import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card-item',
  templateUrl: './card-item.component.html',
  styleUrls: ['./card-item.component.scss']
})
export class CardItemComponent {
  @Input() control?: FormControl;
  @Input() title?: string;
  @Input() subTitle?: string;
  @Input() edit = false;
  @Input() placeholder?: string;

}
