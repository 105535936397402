import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GoogleService } from '../google.service';
import { BiggerThanDateNow } from './../../validators/date-datenow'

import * as _moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators } from 'ngx-editor';
import { GoogleEvent } from '../../models/google-models.model';
import { TranslateService } from '@ngx-translate/core';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'talentway-fe-google-new-event',
  templateUrl: './google-new-event.component.html',
  styleUrls: ['./google-new-event.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class GoogleNewEventComponent implements OnInit {
  @Input() emailAddress = '';
  form: FormGroup;
  cancelMsg: string;
  statusMsg: string;
  @Output() eventCreated: EventEmitter<GoogleEvent> = new EventEmitter()

  constructor(
    private fb: FormBuilder,
    private googleService: GoogleService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {

  }

  ngOnInit(): void {
    let endDate = new Date()
    endDate.setHours(endDate.getHours() + 1)
    this.form = this.fb.group({
      start: this.fb.control(new Date(),{}),
      end: this.fb.control(endDate,{validators: BiggerThanDateNow}),
      title: this.fb.control('',{}),
      description: this.fb.control('',{}),
      attendees: this.fb.control(this.emailAddress,{})
    })
  }

  createEvent(){
    if(this.form.valid){
      this.googleService.createEvent(this.form.value).subscribe(res => {
        this.eventCreated.emit(res.body)
      })

    }else{
      this.eventCreated.emit(null)
      this.translate.get('shared.incorrectFields', { value: '' }).subscribe((resp: string) => {
        this.statusMsg = resp;
      });
      this.translate.get('shared.cancel', { value: '' }).subscribe((resp: string) => {
        this.cancelMsg = resp;
      });
      this.snackBar.open(this.statusMsg, this.cancelMsg, {
        duration: 3000,
      });
    }
  }

  cancel(){
    this.eventCreated.emit(null)
  }
}
