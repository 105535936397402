import { HttpClient, HttpParams } from '@angular/common/http';
import { ElementRef, Inject, Injectable, OnInit, ViewChild } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { EmailSingleSnippetResponse, EmailSnippetResponse, GoogleEventsResponse, GoogleSingleEventResponse } from '../../index'

@Injectable({
  providedIn: 'root'
})
export class GoogleService {
  auth2: any;

  loginGoogleURL = 'google';
  getLoginURL = 'get-login';
  getEmailsURL = 'get-emails';
  getEmailsOrThreadURL = 'get-emails-or-thread';
  createEventURL = 'create-event';
  getEventsURL = 'get-events';
  sendEmailURL = 'send-email';
  updateEventURL = 'update-event'
  deleteEventURL = 'delete-event';

  apiUrl: string;

  constructor(
    @Inject('apiUrl') apiUrl: string,

    private http: HttpClient,
    private apiService: ApiService,
  ) {
    this.apiUrl = apiUrl;
  }
  getUrl(){
    return this.http.get<any>(this.apiUrl + this.getLoginURL).subscribe(res =>{
      window.open(res.body, "_self")
    })

  }

  isLoggedIn(){
    return localStorage.getItem('googleLoggedIn') !== null ? true : false;
  }

  loginGoogle(token: string): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('code', token);
    const headers = this.apiService.getHTTPHeaders()

    return this.http.get<any>(this.apiUrl + this.loginGoogleURL, {headers: headers, params: queryParams});

  }

  sendEmail(data: any): Observable<EmailSingleSnippetResponse> {
    const headers = this.apiService.getHTTPHeaders()

    return this.http.post<any>(this.apiUrl + this.sendEmailURL, JSON.stringify(data), {headers: headers});

  }

  getEmails(label: string): Observable<EmailSnippetResponse> {
    const headers = this.apiService.getHTTPHeaders()
    let queryParams = new HttpParams();
    queryParams = queryParams.append('label', label);

    return this.http.get<any>(this.apiUrl + this.getEmailsURL, {headers: headers, params: queryParams});

  }

  getThread(threadId: string): Observable<EmailSnippetResponse> {
    const headers = this.apiService.getHTTPHeaders()
    let queryParams = new HttpParams();
    queryParams = queryParams.append('threadId', threadId);

    return this.http.get<any>(this.apiUrl + this.getEmailsURL, {headers: headers, params: queryParams});

  }

  getEmailsOrThread(email: string): Observable<EmailSnippetResponse> {
    const headers = this.apiService.getHTTPHeaders()
    let queryParams = new HttpParams();
    queryParams = queryParams.append('email', email);

    return this.http.get<any>(this.apiUrl + this.getEmailsOrThreadURL, {headers: headers, params: queryParams});

  }

  createEvent(data: any){
    const headers = this.apiService.getHTTPHeaders()

    return this.http.post<GoogleSingleEventResponse>(this.apiUrl + this.createEventURL, JSON.stringify(data), {headers: headers});

  }

  getEvents(email: string){
    const headers = this.apiService.getHTTPHeaders()
    let queryParams = new HttpParams();
    queryParams = queryParams.append('email', email);

    return this.http.get<GoogleEventsResponse>(this.apiUrl + this.getEventsURL, {headers: headers, params: queryParams});

  }

  updateEvent(data: any){
    const headers = this.apiService.getHTTPHeaders()

    return this.http.post<GoogleSingleEventResponse>(this.apiUrl + this.updateEventURL, JSON.stringify(data), {headers: headers})
  }

  deleteEvent(data: any){
    const headers = this.apiService.getHTTPHeaders()

    return this.http.post<GoogleSingleEventResponse>(this.apiUrl + this.deleteEventURL, JSON.stringify(data), {headers: headers})
  }

}
