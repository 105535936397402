import { setting } from './setting.model';

export const languageFieldSettings: Array<setting> = [
    {name: 'language'},
    {name: 'level'},
    {name: 'edit'}
]
export const skillFieldSettings: Array<setting> = [
    {name: 'skillName'},
    {name: 'level'},
    {name: 'edit'}
]
