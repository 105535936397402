import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmailSnippet } from '../../../index';
import { GoogleService } from '../google.service';

@Component({
  selector: 'talentway-fe-gmail-snippet',
  templateUrl: './gmail-snippet.component.html',
  styleUrls: ['./gmail-snippet.component.scss'],
})
export class GmailSnippetComponent implements OnInit {
  @Input() snippet: EmailSnippet;
  @Output() openThread: EventEmitter<string> = new EventEmitter()

  constructor(
  ) {}

  ngOnInit(): void {}

  getMailThread(threadId: string){
    this.openThread.emit(threadId)
  }
}
