import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../shared/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { UserFromBE } from '@talentway/shared';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PasswordResetDialog } from './password-reset/password-reset-dialog';
import { BodyUserDataResponse } from 'src/app/shared/models/responses/body-user-response.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit{
  loginForm: FormGroup;
  html: '';
  state$: Observable<any>;

  jobOfferId: number;
  reactionOnJobOffer = false;
  afterEmailVerification = false;
  candidateLogin = false;
  urlPath: string;
  token: string | null;
  verificationHash: string | null;
  loginMsg = '';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog) {
    this.loginForm = this.fb.group({
      email: ['', Validators.email],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.jobOfferId = Number(this.activatedRoute.snapshot.paramMap.get('jobOfferId'));
    this.urlPath = location.pathname.substring(1);

    if (this.jobOfferId || this.urlPath === 'joblogin') {
      this.reactionOnJobOffer = true;
    }

    if(this.authService.afterPasswordReset){
      this.translate.get('login.afterPasswordReset', { value: '' }).subscribe((resp: string) => {
        this.snackBar.open(resp, 'CANCEL', {
          duration: 3000,
        });
      });
    this.authService.afterPasswordReset = false;
    }

    //Token is for user verification, verificationHash is for HR verification (to be split into separate login modules in the future)
    this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
    this.verificationHash = this.activatedRoute.snapshot.queryParamMap.get('verificationHash');
    if(this.token !== null){
      this.candidateLogin = true;
      this.verifyEmail(this.token)
    }
    if(this.verificationHash !== null){
      this.verifyEmail(this.verificationHash)
    }
  }

  checkLoginStatus(): void{
    if(this.authService.isLoggedIn()){
      this.router.navigate(['/dashboard']);
    }
  }

  login(): void {

    this.authService.login(this.loginForm.get('email')?.value, this.loginForm.get('password')?.value).subscribe(res => {
      if (res.status === 200) {
        if(this.candidateLogin){
          this.router.navigate(['/reg'], {});
        }else{
          this.router.navigate(['/dashboard'], {});
        }
      } else {
        let cancel
        let uploadMsg;

        this.translate.get('shared.cancel', { value: '' }).subscribe((resp: string) => {
          cancel = resp;
        });
        if (res.message === "Unauthorized TalentWay confirmation needed") {
          this.translate.get('login.twFirstConfirmation', { value: '' }).subscribe((resp: string) => {
            uploadMsg = resp;
          });
        } else {
          this.translate.get('login.unauthorized', { value: '' }).subscribe((resp: string) => {
            uploadMsg = resp;
          });
        }
        this.snackBar.open(`${uploadMsg}`, cancel, {
          duration: 4000,
          panelClass: ['snackbar-formater']
        });
      }

    });
  }
  joblogin(): void {

    this.authService.login(this.loginForm.get('email')?.value, this.loginForm.get('password')?.value).subscribe(res => {
      if (res && this.jobOfferId) {
        this.router.navigate(['contact-form/', this.jobOfferId]);
      } else if (res && this.urlPath === 'joblogin') {
        this.router.navigate(['job-offers/']);
      } else {
        this.snackBar.open('Unauthorized - please check email and password', 'CANCEL', {
          duration: 3000,
        });
      }
    });
  }

  jobregister(): void {
    this.router.navigate(['jobregistration', this.jobOfferId]);
  }

  forgotPassword(): void {
    const dialogRef = this.dialog.open(PasswordResetDialog, { autoFocus: false });
  }

  verifyEmail(token: string): void{
    this.authService.verifyEmail(token).subscribe(
      res => this.handleVerificationResponse(res),
      error => this.handleVerificationError(error)
    )
  }

  handleVerificationResponse(res: BodyUserDataResponse): void{
    this.translate.get('shared.emailVerified', { value: '' }).subscribe((resp: string) => {
      this.loginMsg = resp;
      this.snackBar.open(resp, 'CANCEL', {
        duration: 3000,
      });
    });
    this.afterEmailVerification = true;
    this.checkLoginStatus();
  }

  handleVerificationError(error: string): void{
    if(error === "Email Already Verified"){
      this.translate.get('login.verificationLinkUsedAlready', { value: '' }).subscribe((resp: string) => {
        this.snackBar.open(resp, 'CANCEL', {
          duration: 3000,
        });
      });
    }else{
      this.translate.get('login.linkInvalid', { value: '' }).subscribe((resp: string) => {
        this.snackBar.open(resp, 'CANCEL', {
          duration: 3000,
        });
      });
    }
    this.afterEmailVerification = true;
    this.checkLoginStatus();

  }

}
