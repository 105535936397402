import { NewRegistrationLanguage, NewRegistrationSkills } from '../../new-registration.model';
import { CandidateEducationClass } from './job-offer-candidate-education.model';
import { CandidateLanguagesSkillsClass } from './job-offer-candidate-languages.model';
import { CandidateOtherSkillsClass } from './job-offer-candidate-other-skills.model';
import { JobInformationClass } from './job-offer-job-information.model';
import { SalaryClass } from './job-offer-job-salary.model';

export class FinallCreateJobOffer {
    id?: number;
    createdAt?: string;
    jobLanguage?: string;
    jobTitle?: string;
    jobType?: string[];
    jobDescription?: string;
    jobBenefits?: string;
    placeOfWork?: string[];
    startDate?: number;
    startDateAsap?: boolean;
    status?: string;
    tenantId?: number;
    jobCreatedByUserId?: number;
    salary?: SalaryClass;
    education?: CandidateEducationClass;
    skillsJO?: NewRegistrationSkillsHR[];
    languagesJO?: NewRegistrationLanguageHR[];
    additionalInfo?: CandidateOtherSkillsClass;
}

export class NewRegistrationSkillsHR {
    skillName!: string;
    skillLevel!: string;
    edit!: boolean;
}

export class NewRegistrationLanguageHR {
    language!: string;
    languageLevel!: string;
    edit!: boolean;
}

