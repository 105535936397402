import { CandidateEducationClass } from '../job-offer/job-offer-candidate-education.model';
import { CandidateLanguagesSkillsClass } from '../job-offer/job-offer-candidate-languages.model';
import { CandidateOtherSkillsClass } from '../job-offer/job-offer-candidate-other-skills.model';
import { JobInformationClass } from '../job-offer/job-offer-job-information.model';
import { SalaryClass } from '../job-offer/job-offer-job-salary.model';
import { CandidatePersonalDataClass } from './candidate-detail-perosnal-data.model';

export class CanddidateDetailModel {
    personalData?: CandidatePersonalDataClass;
    jobInformation?: JobInformationClass;
    salary?: SalaryClass;
    candidateEducation?: CandidateEducationClass;
    candidateLanguagesSkills?: CandidateLanguagesSkillsClass;
    candidateOtherSkills?: CandidateOtherSkillsClass;
}
