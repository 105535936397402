export class NewRegistrationHighSchool {
    id?: number;
    highSchoolName!: string;
    fieldOfStudy!: string;
    city!: string;
    country!: string;
    startDate!: Date;
    endDate!: Date;
    candidateAttachment!: NewRegistrationAttachment;
    edit!: boolean;
    personalDataID?: number;
    attachmentID?: number;
}

export class NewRegistrationAttachment {
    url?: string;
    fileType?: string;
    type?: string;
    fileName!: string;
}

export class NewRegistrationPersonalInfo {
    id?: number;
    gender!: string;
    name!: string;
    surname!: string;
    title?: string;
    titleAfterName?: string;
    birthDate!: Date;
    email!: string;
    telephone!: string;
    // from confluesne?
    // nationality!: string;
    // status!: boolean;
    // experience!: number;
    streetName!: string;
    streetNumber!: string;
    zip!: number;
    city!: string;
    country!: string;
}

export class NewRegistrationUniversity {
    id?: number;
    universityName!: string;
    fieldOfStudy!: string;
    city!: string;
    country!: string;
    startDate!: Date;
    current!: boolean;
    endDate!: Date | null;
    candidateAttachment!: NewRegistrationAttachment;
    edit!: boolean;
    personalDataID?: number;
    attachmentID?: number;
}
export class NewRegistrationProject {
    id?: number;
    title!: string;
    position!: string;
    duration!: number;
    country!: string;
    edit!: boolean;
    personalDataID?: number;
}

export class NewRegistrationLanguage {
    id?: number;
    language!: string;
    level!: string;
    edit!: boolean;
    personalDataID?: number;
}
export class NewRegistrationCourse {
    id?: number;
    courseName!: string;
    certificate!: boolean;
    endDate!: Date;
    specification!: string;
    edit!: boolean;
    personalDataID?: number;
}


export class NewRegistrationWorkExperience {
    id?: number;
    position!: string;
    employer!: string;
    city!: string;
    startDate!: Date;
    current!: boolean;
    endDate!: Date | null;
    country!: string;
    edit!: boolean;
    personalDataID?: number;
}

export class NewRegistrationSkills {
    id?: number;
    skillName!: string;
    level!: string;
    edit!: boolean;
    personalDataID?: number;
}

