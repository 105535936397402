
export const enums = {
    driversLicenseTypes: [
        'AM',
        'A1',
        'A2',
        'A',
        'B1',
        'B',
        'BE',
        'C1',
        'C1E',
        'C',
        'CE',
        'D1',
        'D1E',
        'D',
        'DE',
        'T'],
    jobTypes: [
        'Freelance',
        'Half Time',
        'Full Time'
    ],

    schoolTypes: [
        'Elementary',
        'HighSchool',
        'University'
    ],

    schoolTexts: [
        'dashboard.createNewJobOffer.candidateEducationElementary',
        'dashboard.createNewJobOffer.candidateEducationHighSchool',
        'dashboard.createNewJobOffer.candidateEducationUniversity'
    ],
    workTypes: [
        'Office',
        'Home',
        'Mix',
        'Travel'
    ],
    workTexts: [
        'dashboard.createNewJobOffer.jobOfferplaceOfWorkOffice',
        'dashboard.createNewJobOffer.jobOfferplaceOfWorkHome',
        'dashboard.createNewJobOffer.jobOfferplaceOfWorkMix',
        'dashboard.createNewJobOffer.jobOfferplaceOfWorkTravel'
    ]
}
// export const driverLicenseTypes: string[] = [
//     'AM',
//     'A1',
//     'A2',
//     'A',
//     'B1',
//     'B',
//     'BE',
//     'C1',
//     'C1E',
//     'C',
//     'CE',
//     'D1',
//     'D1E',
//     'D',
//     'DE',
//     'T',
// ]

// export const jobTypes: [
//     'Freelance',
//     'Half Time',
//     'Full Time'
// ]

// export const schoolTypes: [
//     'Elementary',
//     'HighSchool',
//     'University'
// ]

// export const schoolTexts: [
//     'dashboard.createNewJobOffer.candidateEducationElementary',
//     'dashboard.createNewJobOffer.candidateEducationHighSchool',
//     'dashboard.createNewJobOffer.candidateEducationUniversity'
// ]
// export const workTypes: [
//     'Office',
//     'Home',
//     'Mix',
//     'Travel'
// ]

// export const workTexts: [
//     'dashboard.createNewJobOffer.jobOfferplaceOfWorkOffice',
//     'dashboard.createNewJobOffer.jobOfferplaceOfWorkHome',
//     'dashboard.createNewJobOffer.jobOfferplaceOfWorkMix',
//     'dashboard.createNewJobOffer.jobOfferplaceOfWorkTravel'
// ]