import { Component, Input, OnInit } from '@angular/core';
import { EmailSnippet } from '../../../../index';

@Component({
  selector: 'talentway-fe-gmail-thread-message',
  templateUrl: './gmail-thread-message.component.html',
  styleUrls: ['./gmail-thread-message.component.scss'],
})
export class GmailThreadMessageComponent implements OnInit {
  @Input() snippet: EmailSnippet;
  constructor() {}

  ngOnInit(): void {}
}
