export class JobsFromBE {
    id: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    jobLanguage: string;
    jobTitle: string;
    jobType: null;
    jobDescription: string;
    jobBenefits: string;
    placeOfWork: string;
    startDate: string;
    startDateAsap: boolean;
    status: string;
    tenant: Tenant;
    tenantId: number;
    jobCreatedByUserId: number;
    education: Education;
    salary: Salary;
    languagesJO: LanguagesJO[];
    skillsJO: SkillsJO[];
    additionalInfo: AdditionalInfo;
}

export class AdditionalInfo {
    id: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    driversLicense: string | null;
    practicePosition: string;
    graduate: boolean;
    additionalPersonalSkills: string;
    jobInfoID: number;
    practiceYear: number;
}

export class Education {
    id: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    school: string;
    field: string;
    JobInfoID: number;
}

export class LanguagesJO {
    id: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    language: string;
    level: string;
    JobInfoID: number;
}

export class Salary {
    id: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    basicSalary: number;
    upperSalary: number;
    currency: string;
    period: string;
    additionalInfo: string;
    JobInfoID: number;
}

export class SkillsJO {
    id: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    skillName: string;
    level: string;
    JobInfoID: number;
}

export class Tenant {
    id: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    name: string;
    logo: string;
    active: boolean;
    tenantType: string;
    companyID: string;
    taxID: string;
    vat: string;
    inRegister: string;
    companyRepresentativeName: string;
    companyRepresentativeEmail: string;
    employeesNumber: string;
    tenantCharacteristic: string;
    tenantBenefits: null;
    tenantAddresses: null;
}
