<div class="field-body d-flex without-heading-field mt-3">
    <p class="rightHeadings">{{ title | translate}}:
    </p>
    <div *ngIf="!edit">
        <span>{{ (control.value ? 'shared.yes' : 'shared.no') | translate }} </span>
    </div>
    <div *ngIf="edit" class="start-date-class">
        <div data-multiselect class=" d-flex flex-column">
            <input hidden=true matInput>
            <mat-checkbox [formControl]="control" class="button-like">
            </mat-checkbox>
        </div>
    </div>
</div>