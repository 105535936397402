export interface Candidate {
    id: string;
    gender: string;
    name: string;
    surname: string;
    title: string;
    birthDate: string;
    email: string;
    telephoneNumber: string;
    streetName: string;
    streetNumber: string;
    country: string;
    city: string;
    zip: string;
}
