import { Component, Input, OnInit } from '@angular/core';
import { map, Observable, startWith } from 'rxjs';
import { CardItemComponent } from '../card-item.component';

@Component({
  selector: 'app-card-item-autocomplete',
  templateUrl: './card-item-autocomplete.component.html',
  styleUrls: ['./card-item-autocomplete.component.scss', '../card-item.component.scss']
})
export class CardItemAutocompleteComponent extends CardItemComponent implements OnInit {
  @Input() options: string[];

  filtered: Observable<string[]>;

  constructor() {
    super()
   }

  ngOnInit(): void {
    this.filtered = this.control.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filter(value)),
      )
  }

  private filter(value: string): string[] {
    let filterValue = '';
    if (value) {
      filterValue = value.toLowerCase();
    }
    return this.options?.filter(option => option.toLowerCase().includes(filterValue));
  }

}
