import { AfterViewChecked, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { EmailSnippet } from '../../../index';
import { Observable } from 'rxjs';
import { FinalCandidateHRCvrModel } from '../../models/dashboard';

@Component({
  selector: 'talentway-fe-gmail-thread',
  templateUrl: './gmail-thread.component.html',
  styleUrls: ['./gmail-thread.component.scss'],
})
export class GmailThreadComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() emails: EmailSnippet[] = Array<EmailSnippet>()
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @Input() candidate: FinalCandidateHRCvrModel;
  responseEmail: EmailSnippet;
  constructor() {}

  ngOnChanges(){
    if(this.emails !== null) {
      this.responseEmail = this.emails[(this.emails.length-1)];
    }
  }

  ngAfterViewChecked(): void {
    this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight
  }

  ngOnInit(): void {
  }

  addEmail(event: EmailSnippet){
    this.emails.push(event);
  }
}
