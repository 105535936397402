import { AbstractControl, ValidationErrors } from '@angular/forms';

export function BiggerThanDateNow(control: AbstractControl): ValidationErrors | null {
        if (control.value) {
                const dateUnix = Math.round((new Date(control.value).getTime() / 1000));
                const nowDateUnix = Math.floor(new Date().getTime() / 1000.0);
                return dateUnix <= nowDateUnix && control.value !== '' ? { biggerThanDateNow: { value: control.value } } : null;
        } else {return null; }

}
