<div *ngIf="type==='highSchool' || type==='university'">
    <input type="file" class="file-input"
        (change)="addedNewFileToUpload($event, form, type)" #fileUpload>
    <div class="col d-flex justify-content-center align-items-center file-upload">
        <div class="mt-2" *ngIf="edit && !clickableAttachments">
            <button class="save row" mat-stroked-button type="button" (click)="fileUpload.click()">
                <span>{{'shared.uploadFile' | translate}}</span>
                <mat-icon style="color: #A1A1A1;">upload_file</mat-icon>
            </button>
            <div class="d-flex justify-content-center align-items-center mt-3">
                <p class="row justify-content-center m-0">{{item.fileName || getLabel() | translate}}</p>
                <div class="d-flex align-items-center justify-content-center ms-1 delete-attachment" *ngIf="item.fileName" (click)="deleteAttachment(form)">
                    <mat-icon class="icon-close m-0">delete_forever</mat-icon>
                </div>
            </div>
        </div>
        <div *ngIf="!edit && item.fileName && !clickableAttachments" class="d-flex w-100 base">
            <p>{{'stepperForm.university.certificate' | translate}}:</p>
            <span>{{ item.fileName}}</span>
        </div>
    </div>
    <div class="col d-flex justify-content-center align-items-center file-upload"  *ngIf="clickableAttachments">
        <div *ngIf="item.fileName" class="d-flex w-100 base">
            <p>{{'stepperForm.university.certificate' | translate}}:</p>
            <span (click)="downloadAttachment(item.id)" class="link-like">{{ item.fileName}}</span>
        </div>
    </div>
</div>
