import { NewRegistrationHighSchool,
    NewRegistrationAttachment,
    NewRegistrationUniversity,
    NewRegistrationProject,
    NewRegistrationLanguage,
    NewRegistrationWorkExperience,
    NewRegistrationSkills, } from './new-registration.model';

export class AllCVOptionsFromDB {
    genders?: Array<string>;
    languages?: Array<string>;
    languageLevels?: Array<string>;
    skills?: Array<string>;
    skillLevels?: Array<string>;

}

export class AllCVOptionsFromDBResponse {
    body?: AllCVOptionsFromDB;
    message?: string;
    status?: number;
}

export class LongCvResponse {
    body?: FinalLongCvModel;
    message?: string;
    status?: number;
}


export class FinalLongCvModel {
    id?: number;
    gender!: string;
    title?: string;
    titleAfterName?: string;
    birthDate!: Date;
    email!: string;
    telephone!: string;
    // from confluesne?
    // nationality!: string;
    // status!: boolean;
    // experience!: number;
    streetName!: string;
    streetNumber!: number;
    zip!: number;
    city!: string;
    country!: string;
    highSchoolEducations!: NewRegistrationHighSchool[];
    universityEducations!: NewRegistrationUniversity[];
    // courses!: NewRegistrationCourse[];
    projects!: NewRegistrationProject[];
    languages!: NewRegistrationLanguage[];
    workExperiences!: NewRegistrationWorkExperience[];
    skills!: NewRegistrationSkills[];
    candidateAttachment!: NewRegistrationAttachment[];
  }