import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateAgoPipe } from '../pipes/date-ago.pipe';
import { DateAgePipe } from '../pipes/date-age.pipe';
import { MaxCharsDisplayPipe } from '../pipes/max-chars-display';


@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [
    DateAgoPipe,
    DateAgePipe,
    MaxCharsDisplayPipe,
  ],
  exports: [
    CommonModule, FormsModule, TranslateModule, DateAgoPipe, DateAgePipe, MaxCharsDisplayPipe],
  providers: [TranslateService]
})
export class SharedModule {
}
