import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TranslateService } from '@ngx-translate/core';
import { CardItemComponent } from '../card-item.component';

@Component({
  selector: 'app-card-item-multiple',
  templateUrl: './card-item-multiple.component.html',
  styleUrls: ['./card-item-multiple.component.scss', '../card-item.component.scss']
})
export class CardItemMultipleComponent extends CardItemComponent implements OnInit {
  @Input() controlArray: FormArray;
  @Input() values: string[];
  @Input() texts?: string[];
  @Input() toTranslate?: boolean = false;

  combinedArray : {value: string, text: string}[] = []


  constructor(
    // private readonly changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private fb: FormBuilder,
  ) {
    super()
   }


  ngOnInit(): void {
    if(this.texts === undefined){
      this.texts = this.values;
    }
    this.values.forEach((value, index) => this.combinedArray.push({ value: value, text: this.texts[index] }));
  }

  getString(): string{
    let tmp = '';
    
    this.controlArray.controls.map(control =>{
      let text = control.value;
      if(this.toTranslate){
        text = this.texts[this.values.indexOf(control.value)]
        if(text !== undefined && text !== null){
          this.translate.get(text, {value: ''}).subscribe(res =>{
            text = res; 
          })
        }else{
          text = ''
        }
      }

      if(tmp === ''){
        tmp += text;
      }else{
        tmp += ', ' + text;
      }
    })

    return tmp;
  }

  isChecked(value: string): boolean{
    return (this.controlArray.value as Array<string>).indexOf(value) !== -1
  }

  onCheckboxChange(e: MatCheckboxChange, value: string): void {
    if (e.checked) {
      this.controlArray.push(new FormControl(value));
    } else {
      let i = 0;
      this.controlArray.controls.forEach((item: any) => {
        if (item.value == value) {
          this.controlArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
}
