import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardItemComponent } from './card-item.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { CardItemTextComponent } from './card-item-text/card-item-text.component';
import { CardItemMultipleComponent } from './card-item-multiple/card-item-multiple.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CardItemTextareaComponent } from './card-item-textarea/card-item-textarea.component';
import { CardItemSelectComponent } from './card-item-select/card-item-select.component';
import { MatSelectModule } from '@angular/material/select';
import { CardItemDateComponent } from './card-item-date/card-item-date.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CardItemBooleanComponent } from './card-item-boolean/card-item-boolean.component';
import { CardItemAutocompleteComponent } from './card-item-autocomplete/card-item-autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxEditorModule } from 'ngx-editor';
import { CardItemHeaderComponent } from './card-item-header/card-item-header.component';
import { MatIconModule } from '@angular/material/icon';
import { CardItemNonExpandableComponent } from './card-item-non-expandable/card-item-non-expandable.component';
import { CardItemExpandableComponent } from './card-item-expandable/card-item-expandable.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { CardItemAttachemntsComponent } from './card-item-attachemnts/card-item-attachemnts.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    CardItemComponent,
    CardItemTextComponent,
    CardItemMultipleComponent,
    CardItemTextareaComponent,
    CardItemSelectComponent,
    CardItemDateComponent,
    CardItemBooleanComponent,
    CardItemAutocompleteComponent,
    CardItemHeaderComponent,
    CardItemExpandableComponent,
    CardItemNonExpandableComponent,
    CardItemAttachemntsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    TranslateModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatExpansionModule,
    NgxEditorModule,
    MatIconModule,
    MatButtonModule,
  ],
  exports: [
    CardItemComponent,
    CardItemMultipleComponent,
    CardItemTextComponent,
    CardItemTextareaComponent,
    CardItemSelectComponent,
    CardItemDateComponent,
    CardItemBooleanComponent,
    CardItemAutocompleteComponent,
    CardItemExpandableComponent,
    CardItemNonExpandableComponent,
    CardItemHeaderComponent,
  ],
})
export class CardItemModule {}
