<div class="d-flex justify-content-end">
    <button mat-icon-button (click)="onNoClick()" class="close-button"><mat-icon>close</mat-icon></button>
  </div>
  <div>
    <mat-dialog-content>
      <div>
          <h2>{{ message }}</h2>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <p class="p-3" [mat-dialog-close]="true">{{'shared.confirm' | translate}}</p>
        <p class="p-3" [mat-dialog-close]="false">{{'shared.cancel' | translate}}</p>
    </mat-dialog-actions>
  </div>
  