import { setting } from './setting.model';

export const workExperienceFieldSettings: Array<setting> = [
    {name: 'position', type: 'string', label:'stepperForm.workExperiences.position', placeholder: 'stepperForm.examplePlaceHolders.position'},
    {name: 'employer', type: 'string', label:'stepperForm.workExperiences.employer', placeholder: 'stepperForm.examplePlaceHolders.employer'},
    {name: 'city', type: 'string', label:'stepperForm.personalInfo.city', placeholder: 'stepperForm.examplePlaceHolders.city'},
    {name: 'country', type: 'string', label:'stepperForm.personalInfo.country', placeholder: 'stepperForm.examplePlaceHolders.country'},
    {name: 'startDate', type: 'date', label:'stepperForm.highSchool.startDate', placeholder: 'Ex. 4/12/1995'},
    {name: 'endDate', type: 'date', label:'stepperForm.highSchool.endDate', placeholder: 'Ex. 4/12/1995'},
    {name: 'current', type: 'boolean', label:'stepperForm.university.current', placeholder: 'Ex. 4/12/1995'},
    {name: 'edit', type: 'boolean'},
]
