import {Pipe, PipeTransform} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface TranslatedDates {
  justNow: string;
  agoBefore: string;
  agoAfter: string;
}

@Pipe({
    name: 'dateAgo',
    pure: true
})

export class DateAgoPipe implements PipeTransform {
    constructor(private translate: TranslateService){
    }

    transform(value: any, args?: any): any {
      var trans: TranslatedDates
      var transDate: string

      this.translate.get('dateAgoPipe.misc', { value: '' }).subscribe((resp: object) => {
        trans = resp as TranslatedDates
      });

        if (value) {
          const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
          if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
              return trans.justNow;
          const intervals: { [key: string]: number } = {
              'year': 31536000,
              'month': 2592000,
              'week': 604800,
              'day': 86400,
              'hour': 3600,
              'minute': 60,
              'second': 1
          };
          let counter;
          for (const i in intervals) {
              counter = Math.floor(seconds / intervals[i]);
              if (counter > 0)
                  if (counter === 1) {
                      var resultString = i
                      this.translate.get('dateAgoPipe.'+ 'single.' +i, { value: '' }).subscribe((resp: string) => {
                        transDate = resp;
                      });
                      return trans.agoBefore + ' ' + transDate + ' ' + trans.agoAfter; // singular (1 day ago)
                  } else {
                      var resultString = i
                      this.translate.get('dateAgoPipe.'+ 'plural.' +i, { value: '' }).subscribe((resp: string) => {
                        transDate = resp;
                      });
                      return trans.agoBefore + ' ' + counter + ' ' + transDate + ' ' + trans.agoAfter; // plural (2 days ago)
                  }
            }
        }

        return value;
    }

}
