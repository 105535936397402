<div class="field-body d-flex">

    <div *ngIf="!edit" class="d-flex align-start ml-3 mt-3">
        <p *ngIf="title" class="rightHeadings">{{ title | translate}}:</p>
        <span>{{ control.value}}</span>
    </div>
    <div *ngIf="edit"  class="w-100">

        <mat-form-field appearance="outline"  class="w-100">
            <mat-label>{{ subTitle | translate}}</mat-label>
            <input [formControl]="control" matInput matNativeControl
                placeholder="{{ placeholder != undefined ? placeholder : title | translate}}">
        </mat-form-field>

        <div *ngIf=" control.touched && control.invalid" class="alert alert-danger">
            <div *ngIf="control.errors?.required">
                {{'shared.fieldError' | translate}}
            </div>
            <div *ngIf="control.errors?.pattern">
                {{'shared.onlyNumberError' | translate}}
            </div>
        </div>
    </div>
</div>