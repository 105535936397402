import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from './shared/services/permission.guard';

const routes: Routes = [
  {
    path: '', loadChildren: () => import('./views/login/login.module')
      .then(m => m.LoginModule)
  },
  {
    path: 'register', loadChildren: () => import('./views/register/register.module')
      .then(m => m.RegisterModule)
  },
   {
    path: 'dashboard',
     canActivate: [PermissionGuard],
     data: {
       role: 'HR'
     },
    loadChildren: () => import('./views/dashboard/dashboard.module')
      .then(m => m.DashboardModule)
   },
   {
    path: 'passwordReset', loadChildren: () => import('./views/login/password-reset/password-reset.component')
      .then(m => m.PasswordResetComponent)
   },
  {
    path: 'error/:errorType', loadChildren: () => import('./views/error-page/error-page.module')
      .then(m => m.ErrorPageModule)
  },
  {
    path: '**', loadChildren: () => import('./views/error-page/error-page.module')
      .then(m => m.ErrorPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
