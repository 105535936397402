import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'maxCharsDisplay',
    pure: true
})
export class MaxCharsDisplayPipe implements PipeTransform {
    transform(value: string): string {
        if (value === null || value === undefined) {
            return '';
        }
        if (value.length < 16){
            return value;
        }
        return value.slice(0,17).trim() + "..";
    }
}
