import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) { }

  message = '';

  ngOnInit(): void {
    this.translate.get(this.data.message, { value: '' }).subscribe((resp: string) => {
      this.message = resp;
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
