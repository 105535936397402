<div class="new-email d-flex flex-column" [formGroup]="form">

    <div class="w-100 rich-text-content">

        <div class="rich-text ">
            <div class="mt-3">
                <div class="NgxEditor__Wrapper">
                    <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
                    <ngx-editor [editor]="editor" [(ngModel)]="html" formControlName="body"
                        placeholder="Write something.."></ngx-editor>
                </div>
            </div>
            <div *ngIf="form.get('body')?.touched && form.get('body')?.invalid" class="alert alert-danger w-100">
                <div *ngIf="form.get('body')?.errors['required']">
                    {{'shared.fieldError' | translate}}
                </div>
            </div>
        </div>

    </div>

    <button mat-button  class="success-button mt-3" type="button" (click)="sendEmail()">
        <span>{{'shared.google.send' | translate}}</span>
    </button>
</div>