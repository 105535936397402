import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoogleService } from '../google.service';

@Component({
  selector: 'google-login',
  templateUrl: './google-login.component.html',
  styleUrls: ['./google-login.component.scss'],
})
export class GoogleLoginComponent implements OnInit {
  
  constructor(
    private route: ActivatedRoute,
    private googleService: GoogleService
  ) {}

  ngOnInit(): void {
  
  }


  getLoginUrl() {
    this.googleService.getUrl();
  }
}
