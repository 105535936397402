import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CustomValidators } from 'src/app/shared/validators/password';
import { AuthService } from '../../shared/services/auth.service';
import { StepperService } from '../stepper/stepper.service';

export class User {
  firstName: string;
  lastName: string;
  email: string;
  tenantId: string;
  password: string;
  company: string;
  marketing: boolean;
  privacyPolicy: boolean;
  language: string;
}
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;

  customValidators = {
    passwordFormControl: new FormControl('', [
      Validators.required,
      Validators.minLength(8)
    ]),
    verifyPasswordFormControl: new FormControl('', [
      Validators.required,
    ]
    ),
    emailFormControl: new FormControl('', [
      Validators.required,
      Validators.email
    ]
    ),
  };

  hidePS1 = true;
  hidePS2 = true;
  cancel: string;
  inviteHash: string;
  invited = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private stepperService: StepperService,
    private route: ActivatedRoute) {
    this.registerForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: this.customValidators.emailFormControl,
      tenantId: null,
      password: this.customValidators.passwordFormControl,
      verifyPassword: this.customValidators.verifyPasswordFormControl,
      company: new FormControl('', Validators.required),
      marketing: new FormControl(false, Validators.required),
      privacyPolicy: [false, Validators.required],
    }, {
      validator: CustomValidators.mustMatch('password', 'verifyPassword')
    });
  }

  ngOnInit(): void {
    this.inviteHash = this.route.snapshot.queryParams.inviteHash as string;
    if (this.inviteHash) {
      this.stepperService.registeredCheck(this.inviteHash).subscribe(res => {
        if (res.status === 200) {
          if (res.body.id !== 0) {
            this.registerForm.patchValue({
              email: res.body.email,
              company: res.body.tenant.name
            });
            this.registerForm.controls.email.disable();
            this.registerForm.controls.company.disable();
            this.invited = true;
          }
        } else {
          this.router.navigate(['/error/expired'], {});
        }
      });
    }
  }

  toggleHidePassword(pass: string): void {
    if (pass === 'PS1') {
      this.hidePS1 = !this.hidePS1;
    }
    if (pass === 'PS2') {
      this.hidePS2 = !this.hidePS2;
    }
  }

  register(): void {
    this.translate.get('shared.cancel', { value: '' }).subscribe((resp: string) => {
      this.cancel = resp;
    });

    const user = new User();
    user.firstName = this.registerForm.get('firstName')?.value;
    user.lastName = this.registerForm.get('lastName')?.value;
    user.email = this.registerForm.get('email')?.value;
    user.tenantId = this.registerForm.get('tenantId')?.value;
    user.password = this.registerForm.get('password')?.value;
    user.company = this.registerForm.get('company')?.value;
    user.marketing = this.registerForm.get('marketing').value;
    user.privacyPolicy = this.registerForm.get('privacyPolicy').value;
    user.language = localStorage.getItem('language') !== null ? localStorage.getItem('language') : 'en';

    if (this.invited) {
      this.authService.registerInvitedUser(user, this.inviteHash).subscribe(res => {
        if (res) {
          this.router.navigate(['/dashboard'], {});
        }
      }, error => {
        let uploadMsg;
        this.translate.get('register.notRegister', { value: '' }).subscribe((resp: string) => {
          uploadMsg = resp;
        });
        this.snackBar.open(uploadMsg, this.cancel, {
          duration: 3000,
        });
      });
    }

    if (!this.invited) {
      this.authService.register(user).subscribe(res => {
        if (res) {
          this.router.navigate(['/dashboard'], {});
        }
      }, error => {
        let uploadMsg;
        this.translate.get('register.notRegister', { value: '' }).subscribe((resp: string) => {
          uploadMsg = resp;
        });
        this.snackBar.open(uploadMsg, this.cancel, {
          duration: 3000,
        });
      } );
    }
  }
}
