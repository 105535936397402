import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CardItemComponent } from '../card-item.component';
import { Editor, Toolbar } from 'ngx-editor';

@Component({
  selector: 'app-card-item-textarea',
  templateUrl: './card-item-textarea.component.html',
  styleUrls: ['./card-item-textarea.component.scss', '../card-item.component.scss']
})
export class CardItemTextareaComponent extends CardItemComponent implements OnInit, AfterViewChecked {

  editor: Editor;
  html = '';

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super()
    this.editor = new Editor();
   }


  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
    this.control!.enable()
  }

  
  ngOnInit(): void {
    this.html = this.control!.value;
  }

}
