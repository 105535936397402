export class NotesBEModel {
    id: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    userID: number;
    note: string;
    entityID: number;
    type: string;
    user: User;
}

export class User {
    id: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    password: string;
    firstName: string;
    lastName: string;
    tenant: Tenant;
    session: string;
}

export class Tenant {
    id: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    tenantBenefits: string;
    tenantAddresses: string;
}
