<div class="field-body d-flex mt-3">
    <div *ngIf="!edit" class="d-flex align-start ml-3">
        <p class="rightHeadings">{{ title | translate}}: </p>
            <span *ngIf="!this.asap.value">{{ this.control.value | date:'mediumDate' }} </span>
            <span *ngIf="this.asap.value">ASAP</span>
    </div>
    <div *ngIf="edit" class="start-date-class w-100 d-flex">
        <p class="rightHeadings">{{ title | translate}}: </p>
        <div class="d-flex">
            <div class="date-picker">
                <mat-form-field appearance="outline">
                    <mat-label>{{ placeholder | translate}}</mat-label>
                    <input [disabled]="this.asap.value" [matDatepicker]="dp" [formControl]="control" placeholder="Ex. 4/12/1995" matInput>
                    <mat-datepicker-toggle [for]="dp" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #dp panelClass="example-month-picker">
                    </mat-datepicker>
                </mat-form-field>
                <div *ngIf="control.invalid" class="alert alert-danger">
                    <div *ngIf="control.errors?.required">
                        {{'shared.fieldError'
                        | translate}}</div>
                    <div *ngIf="control.errors?.biggerThanDateNow">
                        {{'dashboard.createNewJobOffer.jobOfferStartDateErrorBiggerThanDateNow' |
                        translate}}</div>
                </div>
            </div>
            <div class="d-flex flex-column">
                <input hidden=true matInput>
                <mat-checkbox  [formControl]="asap" class="button-like">ASAP</mat-checkbox>
            </div>
        </div>
    </div>
</div>
