import { Component, Input, OnInit } from '@angular/core';
import { UserFromBE } from '../models/dashboard';

@Component({
  selector: 'profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss'],
})
export class ProfilePictureComponent implements OnInit {
  // @Input() candidate: UserFromBE = new UserFromBE();
  @Input() candidate: any;
  @Input() picture?: any;

  initialsBgColors = ['#ffb897','#a4f8df','#9ce7fc','#ffed91','#bdc6ff','#8efca8','#b0fbe6','#edc196','#87c6fa','#f1aae0'];
  initials = '';

  constructor() {}

  ngOnInit(): void {
    this.initials = this.getCandidateInitials(this.candidate.hrPersonalData.name, this.candidate.hrPersonalData.surname)
  }

  getCandidateInitials(firstName: string, lastName: string) :string{
    return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
  }

}
