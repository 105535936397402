export class Attachment {
    id: number;
    fileName: string;
    uploadedBy: string;
    createdAt: string;
    updatedAt: string;
    url?: string;
    fileType?: string;
    type?: string;
    entityID?: number;
    model?: string;
    tenantId?: number;
}
