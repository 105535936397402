import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { CardItemComponent } from '../card-item.component';

interface editGroup {
  group: AbstractControl,
  value?: boolean,
  groupName?: string,
}

@Component({
  selector: 'app-card-item-header',
  templateUrl: './card-item-header.component.html',
  styleUrls: ['./card-item-header.component.scss', '../card-item.component.scss']
})
export class CardItemHeaderComponent extends CardItemComponent{
  @Output() saveEmitter: EventEmitter<editGroup> = new EventEmitter();
  @Input() group?: AbstractControl;
  @Input() groupName?: string;
  @Output() editEmitter: EventEmitter<editGroup> = new EventEmitter();
  

  constructor() {
    super()
   }


  startEditing(){
    this.edit = true;
    this.group.enable();
    this.editEmitter.emit({group: this.group, value: this.edit})
  }

  cancelEdit(){
    this.edit = false;
    this.group.disable();
    this.editEmitter.emit({group: this.group, value: this.edit})

  }

  save(){
    this.saveEmitter.emit({group: this.group, groupName: this.groupName})
  }

}
